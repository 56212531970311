// Initial state for the VAS Work Logs
const initialWorkLogState = {
    vasWorkLogs: [],
    loading: false,
    error: null,
};

// Reducer for VAS Work Logs
export default function vasWorkLogReducer(state = initialWorkLogState, action) {
    switch (action.type) {
        case 'GETVASREPORT_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'GETVASREPORT_SUCCESS':
            return {
                ...state,
                vasWorkLogs: action.payload,
                loading: false,
            };
        case 'GETVASREPORT_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
