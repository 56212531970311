import React, { useMemo, useState } from 'react';

import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Stack } from '@mui/material';

import { userLogout } from '../../actions/userActions';
import {
    ApplicantPermission,
    ExternalManagerPermission,
    ExternalPermission,
    InternalPermission,
    ManagerPermission,
} from '../../configs/RolePermission';
import NotificationsPopover from '../../layouts/dashboard/header/notification/NotificationsPopover';
import ChangeLanguageBar from '../Others/Language';
import Notification from '../Others/Notification';
import Routing from '../Others/Routing';

const Navigation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);

    const user = useSelector((state) => state.user);

    const Permission = useMemo(() => {
        switch (user.user_type) {
            case 'ET':
                return ExternalPermission;
            case 'IT':
                return InternalPermission;
            case 'EM':
                return ExternalManagerPermission;
            case 'MN':
                return ManagerPermission;
            case 'AP':
                return ApplicantPermission;
            default:
                return [];
        }
    }, [user.user_type]);

    const handleLogout = () => {
        dispatch(userLogout());
        navigate('/');
    };

    const toggleNavbar = () => setExpanded((prev) => !prev);

    const closeNavbar = () => setExpanded(false);

    return (
        <>
            <Notification />
            <Navbar expand="lg" className="topbar" expanded={expanded}>
                <div className="container">
                    <Navbar.Brand>
                        <Link to="/" onClick={closeNavbar}>
                            {/* <img src={logo} alt="logo" className="loginlogo" /> */}
                            <span className="andromeda-logo">ANDROMEDA</span>
                        </Link>
                    </Navbar.Brand>

                    <Stack
                        direction="row"
                        spacing={2}
                        className="d-flex d-lg-none"
                    >
                        <NotificationsPopover />
                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            onClick={toggleNavbar}
                        />
                    </Stack>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            {Permission.map(
                                (p) =>
                                    p.display && (
                                        <Link
                                            to={p.path}
                                            className="nav-link"
                                            key={p.path}
                                            onClick={closeNavbar}
                                        >
                                            {t(`navigation.${p.displayText}`)}
                                        </Link>
                                    )
                            )}
                        </Nav>
                    </Navbar.Collapse>

                    <Navbar.Collapse
                        id="basic-navbar-nav"
                        className="justify-content-end"
                    >
                        <Nav>
                            <div className="d-none d-lg-block mr-3">
                                <NotificationsPopover />
                            </div>
                            <button
                                className="btn btn-logout font-w600"
                                onClick={handleLogout}
                            >
                                {t('login.logout')}
                            </button>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            <ChangeLanguageBar userEmail={user.email} />
            <div className="container">
                <Routing permission={Permission} />
            </div>
        </>
    );
};

export default Navigation;
