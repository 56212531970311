import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Fade,
    FormControlLabel,
    Paper,
    Popper,
    Switch,
    TextField,
} from '@mui/material';

import {
    getUsers,
    getVasReport,
    getWarehouse,
    getWarehousePrincipals,
} from '../../../../../actions';
import { sortObject } from '../../../../../components/HelperFunctions/Others';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DayReportSearch = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        warehouse: 'All',
        principal: 'All',
        task: 'All',
        worker: 'All',
        showFilterPopper: false,
    });

    useEffect(() => {
        dispatch(getWarehouse());
        dispatch(getUsers());
    }, [dispatch]);

    const data = {
        warehouses: sortObject(
            useSelector((state) => state.warehouses),
            'name'
        ),
        principals: sortObject(
            useSelector((state) => state.principal),
            'name'
        ),
        tasks: sortObject(
            useSelector((state) => state.principalTasks),
            'title'
        ),
        users: sortObject(
            useSelector((state) => state.users),
            'first_name'
        ),
    };

    const handleSelectChange = (key, value) => {
        setFilters((prev) => ({ ...prev, [key]: value }));
    };

    const handleMultipleChange = (key, selected, fetchAction) => {
        const ids = selected.map((item) => item.id);
        handleSelectChange(key, ids.length === 1 ? ids[0] : ids);
        if (key === 'warehouse' && ids.length === 1) {
            dispatch(fetchAction(ids[0]));
        }
    };

    const handleFilterToggle = (name, value) => {
        setFilters((prev) => ({
            ...prev,
            filterOptions: { ...prev.filterOptions, [name]: value },
        }));
    };

    const toggleAllFilters = (value) => {
        setFilters((prev) => ({
            ...prev,
            filterOptions: {
                sickleave: value,
                performance: value,
                editmode: value,
            },
        }));
    };

    const search = async (event) => {
        event.preventDefault();
        const searchData = {
            worker: filters.worker,
            warehouse: filters.warehouse,
            principal: filters.principal,
            task: filters.task,
            startDate: filters.startDate,
            endDate: filters.endDate,
            filter: filters.filterOptions,
        };
        await dispatch(getVasReport(searchData));
    };

    return (
        <form onSubmit={search}>
            <div className="form-group mt-3">
                <div className="form-row">
                    {['startDate', 'endDate'].map((dateField) => (
                        <div key={dateField} className="col-lg-6 form-group">
                            <label htmlFor={dateField}>
                                <b>
                                    {t(
                                        `report.${
                                            dateField === 'startDate'
                                                ? 'from'
                                                : 'to'
                                        }`
                                    )}
                                </b>
                            </label>
                            <input
                                id={dateField}
                                className="form-control"
                                value={filters[dateField]}
                                onChange={({ target }) =>
                                    handleSelectChange(dateField, target.value)
                                }
                                type="date"
                                required={dateField === 'startDate'}
                            />
                        </div>
                    ))}
                </div>

                {[
                    { key: 'employee', dataKey: 'users' },
                    { key: 'warehouse', dataKey: 'warehouses' },
                ].map(
                    ({ key, dataKey }) =>
                        data[dataKey] && (
                            <div key={key} className="form-group">
                                <Autocomplete
                                    multiple
                                    onChange={(event, newValue) =>
                                        handleMultipleChange(
                                            key,
                                            newValue,
                                            getWarehousePrincipals
                                        )
                                    }
                                    limitTags={5}
                                    id={`checkboxes-${key}`}
                                    options={data[dataKey]}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) =>
                                        option.first_name || option.name
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    renderOption={(
                                        props,
                                        option,
                                        { selected }
                                    ) => (
                                        <li
                                            {...props}
                                            value={option.id}
                                            key={option.id}
                                        >
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.first_name || option.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t(
                                                `common.select${
                                                    key
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    key.slice(1)
                                                }`
                                            )}
                                        />
                                    )}
                                />
                            </div>
                        )
                )}

                {Number.isInteger(filters.warehouse) && (
                    <div className="form-group">
                        <div className="form-row">
                            <div className="col-lg-6 form-group">
                                <label htmlFor="principal">
                                    <b>{t('common.selectPrincipal')}</b>
                                </label>
                                <select
                                    onChange={({ target }) =>
                                        handleSelectChange(
                                            'principal',
                                            target.value
                                        )
                                    }
                                    className="form-control"
                                    id="principal"
                                >
                                    <option value="All">
                                        {t('report.all')}
                                    </option>
                                    {data.principals.map((principal) => (
                                        <option
                                            key={principal.id}
                                            value={principal.id}
                                        >
                                            {principal.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {filters.principal !== 'All' && (
                                <div className="col-lg-6 form-group">
                                    <label htmlFor="task">
                                        <b>{t('common.selectWorktype')}</b>
                                    </label>
                                    <select
                                        onChange={({ target }) =>
                                            handleSelectChange(
                                                'task',
                                                target.value
                                            )
                                        }
                                        className="form-control"
                                        id="task"
                                    >
                                        <option value="All">
                                            {t('report.all')}
                                        </option>
                                        {data.tasks.map((task) => (
                                            <option
                                                key={task.id}
                                                value={task.id}
                                            >
                                                {t(task.title)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className="d-flex justify-content-between">
                    <button type="submit" className="btn btn-primary">
                        {t('report.search')}
                    </button>
                    <Button
                        variant="outlined"
                        onClick={(event) =>
                            setFilters((prev) => ({
                                ...prev,
                                showFilterPopper: !prev.showFilterPopper,
                                anchorEl: event.currentTarget,
                            }))
                        }
                    >
                        Filters
                    </Button>
                    <Popper
                        open={filters.showFilterPopper}
                        anchorEl={filters.anchorEl}
                        transition
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper elevation={0} variant="outlined">
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        padding={1}
                                    >
                                        {[
                                            'sickleave',
                                            'performance',
                                            'editmode',
                                        ].map((filterName) => (
                                            <FormControlLabel
                                                key={filterName}
                                                control={
                                                    <Switch
                                                        checked={
                                                            filters
                                                                .filterOptions[
                                                                filterName
                                                            ]
                                                        }
                                                        onChange={({
                                                            target,
                                                        }) =>
                                                            handleFilterToggle(
                                                                filterName,
                                                                target.checked
                                                            )
                                                        }
                                                        name={filterName}
                                                    />
                                                }
                                                label={t(
                                                    `navigation.${filterName}`
                                                )}
                                            />
                                        ))}
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Button
                                                onClick={() =>
                                                    toggleAllFilters(false)
                                                }
                                            >
                                                HIDE ALL
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    toggleAllFilters(true)
                                                }
                                            >
                                                SHOW ALL
                                            </Button>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </div>
            </div>
        </form>
    );
};

export default DayReportSearch;
