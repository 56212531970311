import 'bootstrap/dist/css/bootstrap.css';
import './styles/custom.css';
import 'video-react/dist/video-react.css';
import './i18n';

import React from 'react';
import ReactDOM from 'react-dom';

import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { Slide } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';
import store from './store.js';
import ThemeProvider from './theme';

const queryClient = new QueryClient();

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <Router>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider>
                        <SnackbarProvider
                            maxSnack={3}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            TransitionComponent={Slide}
                        >
                            <App />
                        </SnackbarProvider>
                    </ThemeProvider>
                </LocalizationProvider>
            </Router>
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
);
