import {
    deleteVasProductApi,
    getVasProductsApi,
    postVasProductApi,
} from '../api/vasproduct';

export function getVasProducts() {
    return async (dispatch) => {
        dispatch({ type: 'GET_VAS_PRODUCTS_REQUEST' }); // Indicate loading
        try {
            const response = await getVasProductsApi();
            if (response) {
                dispatch({
                    type: 'GET_VAS_PRODUCTS_SUCCESS',
                    payload: response,
                });
            }
        } catch (error) {
            dispatch({
                type: 'GET_VAS_PRODUCTS_FAILURE',
                payload: error.message || 'Failed to fetch VAS products.',
            });
        }
    };
}

export function addVasProduct(data) {
    return async (dispatch) => {
        try {
            data.pcs = Number(data.pcs);
            const response = await postVasProductApi(data);
            dispatch({
                type: 'ADD_VAS_PRODUCT_SUCCESS',
                payload: response,
            });
        } catch (error) {
            console.log('ERROR', error.message || 'Failed to add VAS product.');
        }
    };
}

export function deleteVasProduct(docId) {
    return async (dispatch) => {
        try {
            await deleteVasProductApi(docId);
            dispatch({
                type: 'DELETE_VAS_PRODUCT_SUCCESS',
                payload: docId,
            });
        } catch (error) {
            console.log(
                'ERROR',
                error.message || 'Failed to delete VAS product.'
            );
        }
    };
}
