import { useTranslation } from 'react-i18next';
import { FaFileMedical } from 'react-icons/fa';
import { FcFilingCabinet } from 'react-icons/fc';

import keyicon from '../../../assets/images/key.svg';
import reportlogo from '../../../assets/images/report.svg';
import workericon from '../../../assets/images/Worker-online-2.png';
import { IconLinkCard } from '../IconLinkCard';

const Tools = () => {
    const { t } = useTranslation();

    const toolsItems = [
        {
            link: '/tools/reports',
            imageSrc: reportlogo,
            imageAlt: 'report logo',
            title: t('navigation.reportAndStatistics'),
        },
        {
            link: '/tools/create-shifts',
            imageSrc: keyicon,
            imageAlt: 'create shifts logo',
            title: t('navigation.createShifts'),
        },
        {
            link: '/tools/workingStatus',
            imageSrc: workericon,
            imageAlt: 'working status logo',
            title: t('navigation.liveStatistics'),
        },
        {
            link: '/sickleave/list',
            icon: <FaFileMedical size={120} />,
            title: t('navigation.sickleave-list'),
        },
        {
            link: '/tools/vas-products-list',
            icon: <FcFilingCabinet size={120} />,
            title: t('navigation.vas-products-list'),
        },
    ];

    return (
        <div className="my-2 row">
            {toolsItems.map((item, index) => (
                <IconLinkCard key={index} {...item} />
            ))}
        </div>
    );
};
export default Tools;
