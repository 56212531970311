import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Utility function to format hours to two decimal places
const formatHours = (hours) => {
    return hours.toFixed(2);
};

// Calculate the average performance across all products
const calculateAveragePerformance = (data) => {
    const totalPcs = data.reduce((sum, item) => sum + item.pcsCompleted, 0);
    const totalHours = data.reduce((sum, item) => sum + item.totalHours, 0);
    if (totalHours === 0) return '0.00';
    return (totalPcs / totalHours).toFixed(2);
};

const RenderVasReport = () => {
    const { vasWorkLogs: logs, loading } = useSelector(
        (state) => state.vasWorkLog
    ); // Get state from the store
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [sending, setSending] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSendEmail = async () => {
        setSending(true);
        setError('');
        setSuccess('');

        // Generate email body with report data
        const reportRows = formattedReport
            .map(
                (product) => `
                <h3>Tuotekoodi: ${product.productCode}</h3>
                <p>Laatikot: ${product.boxes}</p>
                <p>Kappalemäärä: ${product.pcsCompleted.toFixed(2)}</p>
                <p>Työtunnit: ${formatHours(product.totalHours)} h</p>
                <p>Teho: ${product.efficiency.toFixed(2)} kpl/h</p>
            `
            )
            .join('');

        const averagePerformance = calculateAveragePerformance(formattedReport);

        const emailBody = `
            <div>
                <h2>A-Halli tuotantotiimi päiväraportti</h2>
                ${reportRows}
                <h3>Päivän keskiteho: ${averagePerformance} kpl/h</h3>
                <p>Parhain terveisin</p>
                <p>A-Halli tuotantotiimi</p>
            </div>
        `;

        const payload = {
            to: email,
            subject: 'A-Halli tuotantotiimi päiväraportti',
            body: emailBody,
        };

        try {
            const response = await fetch(
                'https://resend-test-mu.vercel.app/api/send-email/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${process.env.REACT_APP_SEND_MAIL_API_TOKEN}`,
                    },
                    body: JSON.stringify(payload),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to send email');
            }

            setSuccess('Email sent successfully!');
        } catch (err) {
            setError(err.message);
        } finally {
            setSending(false);
        }
    };

    if (loading) {
        return <Typography>Loading report data...</Typography>;
    }

    if (!logs || logs.length === 0) {
        return (
            <Box>
                <Typography>
                    No data available for the selected filters.
                </Typography>
            </Box>
        );
    }

    // Aggregate data by productCode
    const reportData = logs.reduce((acc, log) => {
        log.logs.forEach((entry) => {
            const { productCode, boxesCompleted, pcs, duration } = entry;
            const durationInHours = duration / 3600; // Convert seconds to hours
            const pcsCompleted = boxesCompleted * pcs; // Total pcs for this entry

            if (!acc[productCode]) {
                acc[productCode] = {
                    boxes: 0,
                    pcsCompleted: 0,
                    totalDuration: 0, // in hours
                };
            }

            acc[productCode].boxes += boxesCompleted;
            acc[productCode].pcsCompleted += pcsCompleted;
            acc[productCode].totalDuration += durationInHours;
        });
        return acc;
    }, {});

    // Prepare formatted report data
    const formattedReport = Object.entries(reportData).map(
        ([productCode, data]) => ({
            productCode,
            boxes: data.boxes,
            pcsCompleted: data.pcsCompleted, // Total pcs
            totalHours: data.totalDuration, // Total hours
            efficiency:
                data.totalDuration > 0
                    ? data.pcsCompleted / data.totalDuration
                    : 0, // pcs/hour
        })
    );

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box>
            {formattedReport.map((product) => (
                <Box key={product.productCode} mb={3}>
                    <Typography variant="h6">
                        Tuotekoodi: {product.productCode}
                    </Typography>
                    <Typography>Laatikot: {product.boxes}</Typography>
                    <Typography>
                        Kappalemäärä: {product.pcsCompleted.toFixed(2)}
                    </Typography>
                    <Typography>
                        Työtunnit: {formatHours(product.totalHours)} h
                    </Typography>
                    <Typography>
                        Teho: {product.efficiency.toFixed(2)} kpl/h
                    </Typography>
                </Box>
            ))}
            <Typography variant="h6">
                Päivän keskiteho: {calculateAveragePerformance(formattedReport)}{' '}
                kpl/h
            </Typography>

            <Button
                variant="contained"
                color="primary"
                onClick={() => setShowPopup(true)}
            >
                Send Report via Email
            </Button>

            <Modal
                open={showPopup}
                onClose={() => setShowPopup(false)}
                aria-labelledby="send-report-modal"
                aria-describedby="send-report-description"
            >
                <Box sx={modalStyle}>
                    <Typography
                        id="send-report-modal"
                        variant="h6"
                        component="h2"
                    >
                        Send Report
                    </Typography>
                    <TextField
                        fullWidth
                        label="Recipient Email"
                        variant="outlined"
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSendEmail}
                            disabled={sending}
                        >
                            {sending ? 'Sending...' : 'Send'}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setShowPopup(false)}
                        >
                            Cancel
                        </Button>
                    </Box>
                    {error && <Typography color="error">{error}</Typography>}
                    {success && (
                        <Typography color="success">{success}</Typography>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default RenderVasReport;
