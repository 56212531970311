import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';

// @mui
import {
    Box,
    Card,
    Container,
    FormControlLabel,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Tooltip,
} from '@mui/material';

// redux
import { getProducts } from '../../actions/inventoryActions';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/iconify';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/scrollbar';
import {
    TableEmptyRows,
    TableHeadCustom,
    TableNoData,
    TableSelectedAction,
    TableSkeleton,
} from '../../components/table';
// hooks
import useSettings from '../../hooks/useSettings';
import useTable, {
    emptyRows,
    getComparator,
} from '../../hooks/useTable';
// sections
import {
    ProductTableRow,
    ProductTableToolbar,
} from '../../sections/tools/inventory';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Product', align: 'left' },
    { id: 'ean', label: 'ean', align: 'left' },
    { id: 'locations', label: 'Locations', align: 'center', width: 180 },
    { id: 'total', label: 'Total', align: 'right' },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function EcommerceProductList() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        //
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable({
        defaultOrderBy: 'total',
    });

    const { themeStretch } = useSettings();

    const dispatch = useDispatch();

    const products = useSelector((state) => state.inventory);

    const [isLoading, setisLoading] = useState(false);

    const [tableData, setTableData] = useState([]);

    const [filterName, setFilterName] = useState('');

    useEffect(() => {
        setisLoading(true);
        dispatch(getProducts());
        setisLoading(false);
    }, [dispatch]);

    useEffect(() => {
        if (products && products.length) {
            setTableData(products);
        }
    }, [products]);

    const handleFilterName = (filterName) => {
        setFilterName(filterName);
        setPage(0);
    };

    const handleDeleteRow = (id) => {
        const deleteRow = tableData.filter((row) => row.id !== id);
        setSelected([]);
        setTableData(deleteRow);
    };

    const handleDeleteRows = (selected) => {
        const deleteRows = tableData.filter(
            (row) => !selected.includes(row.id)
        );
        setSelected([]);
        setTableData(deleteRows);
    };

    const handleEditRow = (id) => {
        console.log(id);
    };

    const dataFiltered = applySortFilter({
        tableData,
        comparator: getComparator(order, orderBy),
        filterName,
    });

    const denseHeight = dense ? 60 : 80;

    const isNotFound =
        (!dataFiltered.length && !!filterName) ||
        (!isLoading && !dataFiltered.length);

    return (
        <Page title="Inventory: Product List">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading="Product List"
                    links={[
                        { name: 'Dashboard', href: '/' },
                        { name: 'Tools', href: '/tools' },
                        { name: 'Inventory' },
                    ]}
                />

                <h1 className="text-center text-red">In Development</h1>

                <Card>
                    <ProductTableToolbar
                        filterName={filterName}
                        onFilterName={handleFilterName}
                    />
                    <Scrollbar>
                        <TableContainer
                            sx={{ minWidth: 960, position: 'relative' }}
                        >
                            {selected.length > 0 && (
                                <TableSelectedAction
                                    dense={dense}
                                    numSelected={selected.length}
                                    rowCount={tableData.length}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                    actions={
                                        <Tooltip title="Delete">
                                            <IconButton
                                                color="primary"
                                                onClick={() =>
                                                    handleDeleteRows(selected)
                                                }
                                            >
                                                <Iconify
                                                    icon={'eva:trash-2-outline'}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                />
                            )}

                            <Table size={dense ? 'small' : 'medium'}>
                                <TableHeadCustom
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={selected.length}
                                    onSort={onSort}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                />

                                <TableBody>
                                    {(isLoading
                                        ? [...Array(rowsPerPage)]
                                        : dataFiltered
                                    )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row, index) =>
                                            row ? (
                                                <ProductTableRow
                                                    key={row.id}
                                                    row={row}
                                                    selected={selected.includes(
                                                        row.id
                                                    )}
                                                    onSelectRow={() =>
                                                        onSelectRow(row.id)
                                                    }
                                                    onDeleteRow={() =>
                                                        handleDeleteRow(row.id)
                                                    }
                                                    onEditRow={() =>
                                                        handleEditRow(row.name)
                                                    }
                                                />
                                            ) : (
                                                !isNotFound && (
                                                    <TableSkeleton
                                                        key={index}
                                                        sx={{
                                                            height: denseHeight,
                                                        }}
                                                    />
                                                )
                                            )
                                        )}

                                    <TableEmptyRows
                                        height={denseHeight}
                                        emptyRows={emptyRows(
                                            page,
                                            rowsPerPage,
                                            tableData.length
                                        )}
                                    />

                                    <TableNoData isNotFound={isNotFound} />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <Box sx={{ position: 'relative' }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={dataFiltered.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={dense}
                                    onChange={onChangeDense}
                                />
                            }
                            label="Dense"
                            sx={{
                                px: 3,
                                py: 1.5,
                                top: 0,
                                position: { md: 'absolute' },
                            }}
                        />
                    </Box>
                </Card>
            </Container>
        </Page>
    );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName }) {
    let stabilizedThis = tableData.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    tableData = stabilizedThis.map((el) => el[0]);

    if (filterName) {
        tableData = tableData.filter(
            (item) =>
                item.product.name
                    .toLowerCase()
                    .indexOf(filterName.toLowerCase()) !== -1
        );
    }

    return tableData;
}
