import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

// @mui
import {
    Box,
    Button,
    Card,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Tooltip,
    Typography,
} from '@mui/material';

import {
    deleteVasProduct,
    getVasProducts,
} from '../../../actions/vasProductActions';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/iconify';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/scrollbar';
import {
    TableHeadCustom,
    TableNoData,
    TableSelectedAction,
    TableSkeleton,
} from '../../../components/table';
// hooks
import useSettings from '../../../hooks/useSettings';
import useTable, { emptyRows, getComparator } from '../../../hooks/useTable';
import VasProductTableRow from '../../../sections/tools/vas-products/VasProductTableRow';
// sections
import VasProductTableToolbar from '../../../sections/tools/vas-products/VasProductTableToolbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: '', label: '', align: 'left' },
    { id: 'productCode', label: 'Product Code', align: 'left' },
    { id: 'productDescription', label: 'Product description', align: 'left' },
    { id: 'productType', label: 'Type', align: 'center', width: 180 },
    { id: 'vasPrincipal', label: 'Principal', align: 'right' },
    { id: 'pcs', label: 'Pcs', align: 'right' },
    { id: 'uom', label: 'UOM', align: 'right' },
    { id: 'eanCode', label: 'BOX EAN-Code', align: 'right' },
];

// ----------------------------------------------------------------------

export default function VasProductList() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        //
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable({
        defaultOrderBy: 'productCode',
    });

    const { themeStretch } = useSettings();

    const dispatch = useDispatch();

    const { vasProducts, loading, error } = useSelector(
        (state) => state.vasProducts
    );

    const [filterName, setFilterName] = useState('');
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedToDelete, setSelectedToDelete] = useState([]);

    useEffect(() => {
        dispatch(getVasProducts());
    }, [dispatch]);

    const handleFilterName = (filterName) => {
        setFilterName(filterName);
        setPage(0);
    };

    const handleDeleteRow = (id) => {
        dispatch(deleteVasProduct(id));
        setSelected([]);
    };

    const handleDeleteRows = (selected) => {
        setSelectedToDelete(selected);
        setConfirmOpen(true);
    };

    const handleConfirmDelete = () => {
        selectedToDelete.forEach((id) => dispatch(deleteVasProduct(id)));
        setConfirmOpen(false); // Close the dialog
        setSelected([]);
    };

    const handleCancelDelete = () => {
        setConfirmOpen(false);
        setSelectedToDelete([]);
    };

    const handleEditRow = (id) => {
        console.log(id);
    };

    const dataFiltered = applySortFilter({
        tableData: vasProducts,
        comparator: getComparator(order, orderBy),
        filterName,
    });

    const denseHeight = dense ? 60 : 80;

    return (
        <>
            <Page title="Inventory: Product List">
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading="Product List"
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Tools', href: '/tools' },
                            { name: 'VAS Product' },
                        ]}
                    />

                    <Box sx={{ mb: 3 }}>
                        <Button
                            variant="contained"
                            startIcon={<Iconify icon={'eva:plus-fill'} />}
                            component={RouterLink}
                            to="/tools/vas-products-list/add"
                        >
                            Add New Product
                        </Button>
                    </Box>

                    <Card>
                        {loading ? (
                            <TableSkeleton sx={{ height: denseHeight }} />
                        ) : error ? (
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'error.main',
                                    textAlign: 'center',
                                    padding: 4,
                                }}
                            >
                                {error}
                            </Typography>
                        ) : (
                            <>
                                <VasProductTableToolbar
                                    filterName={filterName}
                                    onFilterName={handleFilterName}
                                />
                                <Scrollbar>
                                    <TableContainer
                                        sx={{
                                            minWidth: 960,
                                            position: 'relative',
                                        }}
                                    >
                                        {selected.length > 0 && (
                                            <TableSelectedAction
                                                dense={dense}
                                                numSelected={selected.length}
                                                rowCount={vasProducts.length}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        vasProducts.map(
                                                            (row) => row.id
                                                        )
                                                    )
                                                }
                                                actions={
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() =>
                                                                handleDeleteRows(
                                                                    selected
                                                                )
                                                            }
                                                        >
                                                            <Iconify
                                                                icon={
                                                                    'eva:trash-2-outline'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            />
                                        )}
                                        <Table
                                            size={dense ? 'small' : 'medium'}
                                        >
                                            <TableHeadCustom
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={TABLE_HEAD}
                                                rowCount={vasProducts.length}
                                                numSelected={selected.length}
                                                onSort={onSort}
                                                onSelectAllRows={(checked) =>
                                                    onSelectAllRows(
                                                        checked,
                                                        vasProducts.map(
                                                            (row) => row.id
                                                        )
                                                    )
                                                }
                                            />

                                            <TableBody>
                                                {loading ? (
                                                    [...Array(rowsPerPage)].map(
                                                        (_, index) => (
                                                            <tr
                                                                key={index}
                                                                style={{
                                                                    height: denseHeight,
                                                                }}
                                                            >
                                                                <td
                                                                    colSpan={
                                                                        TABLE_HEAD.length
                                                                    }
                                                                >
                                                                    <TableSkeleton />
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : error ? (
                                                    <tr>
                                                        <td
                                                            colSpan={
                                                                TABLE_HEAD.length
                                                            }
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                padding: 20,
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                color="error"
                                                            >
                                                                {error}
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                ) : dataFiltered.length > 0 ? (
                                                    dataFiltered
                                                        .slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage +
                                                                rowsPerPage
                                                        )
                                                        .map((row) => (
                                                            <VasProductTableRow
                                                                key={row.id}
                                                                row={row}
                                                                selected={selected.includes(
                                                                    row.id
                                                                )}
                                                                onSelectRow={() =>
                                                                    onSelectRow(
                                                                        row.id
                                                                    )
                                                                }
                                                                onDeleteRow={() =>
                                                                    handleDeleteRow(
                                                                        row.id
                                                                    )
                                                                }
                                                                onEditRow={() =>
                                                                    handleEditRow(
                                                                        row.id
                                                                    )
                                                                }
                                                            />
                                                        ))
                                                ) : (
                                                    <tr>
                                                        <td
                                                            colSpan={
                                                                TABLE_HEAD.length
                                                            }
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                padding: 20,
                                                            }}
                                                        >
                                                            <TableNoData
                                                                isNotFound
                                                            />
                                                        </td>
                                                    </tr>
                                                )}

                                                {emptyRows(
                                                    page,
                                                    rowsPerPage,
                                                    vasProducts.length
                                                ) > 0 && (
                                                    <tr
                                                        style={{
                                                            height: denseHeight,
                                                        }}
                                                    >
                                                        <td
                                                            colSpan={
                                                                TABLE_HEAD.length
                                                            }
                                                        />
                                                    </tr>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>
                            </>
                        )}

                        <Box sx={{ position: 'relative' }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={dataFiltered.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={onChangePage}
                                onRowsPerPageChange={onChangeRowsPerPage}
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={dense}
                                        onChange={onChangeDense}
                                    />
                                }
                                label="Dense"
                                sx={{
                                    px: 3,
                                    py: 1.5,
                                    top: 0,
                                    position: { md: 'absolute' },
                                }}
                            />
                        </Box>
                    </Card>
                </Container>
            </Page>

            {/* Confirmation Dialog */}
            <Dialog
                open={confirmOpen}
                onClose={handleCancelDelete}
                aria-labelledby="confirm-delete-title"
                aria-describedby="confirm-delete-description"
            >
                <DialogTitle id="confirm-delete-title">
                    Confirm Deletion
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to delete the selected items?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        color="error"
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName }) {
    const filteredData = tableData.filter((row) => {
        const productCode = row.productCode?.toLowerCase();
        const productDescription = row.productDescription?.toLowerCase();
        const productType = row.productType?.toLowerCase();
        const principal = row.vasPrincipal?.toLowerCase();
        const pcs = row.pcs.toString();
        const uom = row.uom?.toLowerCase();
        const eanCode = row.eanCode?.toLowerCase();

        return (
            productCode.includes(filterName) ||
            productDescription.includes(filterName) ||
            productType.includes(filterName) ||
            principal.includes(filterName) ||
            pcs.includes(filterName) ||
            uom.includes(filterName) ||
            eanCode.includes(filterName)
        );
    });

    if (filterName) {
        return filteredData.sort(comparator);
    }
    return tableData.sort(comparator);
}
