import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { BsFillGearFill } from 'react-icons/bs';
import {
    FaBriefcaseMedical,
    FaClipboardList,
    FaTools,
    FaUserClock,
} from 'react-icons/fa';

import { IconLinkCard } from '../IconLinkCard';
import Rules from './Rules';

const Staff = () => {
    const [shown, setShown] = useState(false);
    const { t } = useTranslation();

    return (
        <div className="my-2 row">
            <IconLinkCard
                link="/timer"
                icon={<FaUserClock />}
                title={t('navigation.stamp')}
            />
            <IconLinkCard
                link="/tools"
                icon={<FaTools />}
                title={t('navigation.tools')}
            />
            <IconLinkCard
                link="/sickleave"
                icon={<FaBriefcaseMedical />}
                title={t('navigation.sickleave')}
            />
            <IconLinkCard
                link="/setting"
                icon={<BsFillGearFill />}
                title={t('navigation.setting')}
            />
            <IconLinkCard
                link="/safety"
                icon={<AiFillSafetyCertificate />}
                title={t('navigation.safety')}
            />
            <IconLinkCard
                link=""
                icon={<FaClipboardList />}
                title={t('navigation.rules')}
                onClick={() => setShown(true)}
            />
            {shown && <Rules setShown={setShown} show={shown} />}
        </div>
    );
};

export default Staff;
