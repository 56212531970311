import { endOfDay, startOfDay } from 'date-fns';
import {
    addDoc,
    deleteDoc,
    doc,
    getDocs,
    orderBy,
    query,
    where,
} from 'firebase/firestore';

import {
    vasProductsCollectionRef,
    vasWorkLogsCollectionRef,
} from '../db/firestore';
import { formatDocs } from '../utils/formatFirestore';

/**
 * Fetch completed VAS work logs based on filters.
 * @param {Object} filters - The filters for the query.
 * @param {Date} filters.startDay - The start date for filtering logs.
 * @param {Date} filters.endDay - The end date for filtering logs.
 * @param {Array} filters.workerIds - Array of worker IDs to filter logs.
 * @param {Array} filters.warehouseIds - Array of warehouse IDs to filter logs.
 * @returns {Array} Filtered logs.
 */
export const getFilteredVasWorkLogsApi = async (filters) => {
    const { startDay, endDay, workerIds, warehouseIds } = filters;

    try {
        // Define Firestore query constraints
        const constraints = [
            where('createdAt', '>=', startOfDay(startDay)),
            where('createdAt', '<=', endOfDay(endDay)),
        ];

        if (workerIds && workerIds.length > 0) {
            constraints.push(where('workerId', 'in', workerIds));
        }

        if (warehouseIds && warehouseIds.length > 0) {
            constraints.push(where('warehouseId', 'in', warehouseIds));
        }

        const q = query(
            vasWorkLogsCollectionRef,
            ...constraints,
            orderBy('createdAt', 'desc')
        );

        // Fetch and process query results
        const querySnapshot = await getDocs(q);
        const logs = [];

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const convertedLogs = data.vasWorkLog.map((log) => ({
                ...log,
                documentId: doc.id, // Add document ID for reference
                startTime: log.startTime?.toDate?.() || log.startTime,
                endTime: log.endTime?.toDate?.() || log.endTime,
            }));
            logs.push({
                documentId: doc.id,
                workerId: data.workerId,
                shiftId: data.shiftId,
                warehouseId: data.warehouseId,
                logs: convertedLogs,
            });
        });

        return logs;
    } catch (error) {
        console.error('Error fetching filtered logs:', error);
        throw error;
    }
};

export const getVasProductsApi = async () => {
    const response = await getDocs(vasProductsCollectionRef);
    return formatDocs(response);
};

export const postVasProductApi = async (data) => {
    const response = await addDoc(vasProductsCollectionRef, data);
    return formatDocs(response);
};

export const deleteVasProductApi = async (docId) => {
    try {
        const docRef = doc(vasProductsCollectionRef, docId);
        await deleteDoc(docRef);
        return { success: true, message: 'Document deleted successfully' };
    } catch (error) {
        return { success: false, message: error.message };
    }
};
