import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Navigation = () => {
    const user = useSelector((state) => state.user);
    return (
        <>
            {!user && (
                <div className="bg-red d-flex justify-content-center">
                    <Link to="/">
                        <span className="andromeda-logo">ANDROMEDA</span>
                    </Link>
                </div>
            )}
        </>
    );
};

export default Navigation;
