import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import UploadIcon from '@mui/icons-material/Upload';
// @mui
import {
    Autocomplete,
    Breadcrumbs,
    Button,
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';

import { addVasProduct } from '../../../actions/vasProductActions';
import {
    getPrincipalsTasks,
    getWarehouse,
    getWarehousePrincipals,
} from '../../../actions/warehouseActions';
import Page from '../../../components/Page';
import useSettings from '../../../hooks/useSettings';

const initialFormData = {
    eanCode: '',
    pcs: '',
    vasPrincipal: '',
    productCode: '',
    productDescription: '',
    productType: '',
    uom: '',
    warehouse: '',
    principal: '',
    task: '',
    workTypeid: '',
    img_url: '', // New field for image URL
};

const predefinedProductTypes = ['Noodle', 'Sauce']; // Predefined options
const predefinedUOMs = ['Box', 'Pallet']; // Predefined UOM options

const VasProductAdd = () => {
    const { themeStretch } = useSettings();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState(initialFormData);
    const [uploading, setUploading] = useState(false); // State to handle upload status

    const { warehouses } = useSelector((state) => state);
    const { principal: principals } = useSelector((state) => state);
    const { principalTasks: tasks } = useSelector((state) => state);

    useEffect(() => {
        dispatch(getWarehouse());
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleChangeWarehouse = (e) => {
        const warehouseId = e.target.value;
        setFormData((prev) => ({
            ...prev,
            warehouse: warehouseId,
            principal: '',
            task: '',
            workTypeid: '',
        }));
        if (warehouseId) {
            dispatch(getWarehousePrincipals(warehouseId));
        }
    };

    const handleChangePrincipal = (e) => {
        const principalId = e.target.value;
        setFormData((prev) => ({
            ...prev,
            principal: principalId,
            task: '',
            workTypeid: '',
        }));
        if (principalId) {
            dispatch(getPrincipalsTasks(formData.warehouse, principalId));
        }
    };

    const handleChangeTask = (e) => {
        const taskId = e.target.value;
        setFormData((prev) => ({
            ...prev,
            task: taskId,
            workTypeid: taskId,
        }));
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setUploading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'dth-vas');
        try {
            const response = await fetch(
                'https://api.cloudinary.com/v1_1/dgfkvkcpm/image/upload',
                {
                    method: 'POST',
                    body: formData,
                }
            );
            const data = await response.json();
            setFormData((prev) => ({ ...prev, img_url: data.secure_url }));
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addVasProduct(formData));
        navigate('/tools/vas-products-list');
    };

    return (
        <Page title="Inventory: Product List">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Link color="inherit" href="/tools">
                        Tools
                    </Link>
                    <Link color="inherit" href="/tools/vas-products-list">
                        VAS Product
                    </Link>
                    <Typography color="textPrimary">VAS Product Add</Typography>
                </Breadcrumbs>
                <Typography variant="h4" gutterBottom>
                    Add Vas Product
                </Typography>
                <form onSubmit={handleSubmit}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        required
                    >
                        <InputLabel required>Warehouse</InputLabel>
                        <Select
                            label="Warehouse"
                            name="warehouse"
                            value={formData.warehouse}
                            onChange={handleChangeWarehouse}
                        >
                            <MenuItem value="">Select Warehouse</MenuItem>
                            {warehouses?.map((warehouse) => (
                                <MenuItem
                                    key={warehouse.id}
                                    value={warehouse.id}
                                >
                                    {warehouse.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {formData.warehouse && (
                        <FormControl
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                        >
                            <InputLabel required>Principal</InputLabel>
                            <Select
                                label="Principal"
                                name="principal"
                                value={formData.principal}
                                onChange={handleChangePrincipal}
                            >
                                <MenuItem value="">Select Principal</MenuItem>
                                {principals?.map((principal) => (
                                    <MenuItem
                                        key={principal.id}
                                        value={principal.id}
                                    >
                                        {principal.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {formData.principal && (
                        <FormControl
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                        >
                            <InputLabel required>Task</InputLabel>
                            <Select
                                label="Task"
                                name="task"
                                value={formData.task}
                                onChange={handleChangeTask}
                            >
                                <MenuItem value="">Select Task</MenuItem>
                                {tasks?.map((task) => (
                                    <MenuItem key={task.id} value={task.id}>
                                        {task.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <TextField
                        label="Product Code"
                        name="productCode"
                        value={formData.productCode}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />

                    <TextField
                        label="Product Description"
                        name="productDescription"
                        value={formData.productDescription}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />

                    <Autocomplete
                        freeSolo
                        options={predefinedProductTypes}
                        value={formData.productType}
                        onChange={(event, newValue) => {
                            setFormData((prev) => ({
                                ...prev,
                                productType: newValue,
                            }));
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Product Type"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />

                    <Autocomplete
                        freeSolo
                        options={predefinedUOMs}
                        value={formData.uom}
                        onChange={(event, newValue) => {
                            setFormData((prev) => ({ ...prev, uom: newValue }));
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="UOM"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />

                    <TextField
                        label="VAS Principal"
                        name="vasPrincipal"
                        value={formData.vasPrincipal}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />

                    <TextField
                        label="Pcs"
                        name="pcs"
                        type="number"
                        value={formData.pcs}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />

                    <TextField
                        label="BOX EAN-Code"
                        name="eanCode"
                        value={formData.eanCode}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />

                    <FormControl
                        fullWidth
                        sx={{
                            mt: 2,
                            border: '1px dashed #ccc',
                            borderRadius: '8px',
                            p: 2,
                            textAlign: 'center',
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        <Typography variant="body1" gutterBottom>
                            Upload Product Image
                        </Typography>

                        {!formData.img_url ? (
                            <>
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{ mb: 2 }}
                                    startIcon={<UploadIcon />}
                                >
                                    Choose File
                                    <input
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                    />
                                </Button>
                                {uploading && (
                                    <CircularProgress
                                        sx={{
                                            display: 'block',
                                            mx: 'auto',
                                            mb: 2,
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <div>
                                <img
                                    src={formData.img_url}
                                    alt="Uploaded"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '200px',
                                        marginTop: '10px',
                                        borderRadius: '8px',
                                    }}
                                />
                                <Typography sx={{ mt: 1 }} color="green">
                                    Image uploaded successfully!
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{ mt: 2 }}
                                    onClick={() =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            img_url: '',
                                        }))
                                    }
                                >
                                    Remove Image
                                </Button>
                            </div>
                        )}
                    </FormControl>

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                    >
                        Submit
                    </Button>
                </form>
            </Container>
        </Page>
    );
};

export default VasProductAdd;
