import { getPerformanceApi } from '../api/performance';

export function getPerformance() {
  return async (dispatch) => {
    try {
      const response = await getPerformanceApi();

      dispatch({
        type: 'GETPERFORMANCES',
        payload: response,
      });
    } catch (error) {
      console.log('ERROR', error);
    }
  };
}
