import PropTypes from 'prop-types';

// @mui
import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';

//

// ----------------------------------------------------------------------

VasProductTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
};

export default function VasProductTableRow({
    row,
    selected,
    onEditRow,
    onSelectRow,
    onDeleteRow,
}) {
    const {
        eanCode,
        pcs,
        principal,
        productCode,
        productDescription,
        productType,
        uom,
    } = row;

    return (
        <TableRow hover selected={selected}>
            <TableCell padding="checkbox">
                <Checkbox checked={selected} onClick={onSelectRow} />
            </TableCell>

            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {productCode}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {productDescription}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {productType}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {principal}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {pcs}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="subtitle2" noWrap>
                    {uom}
                </Typography>
            </TableCell>
            <TableCell>{eanCode}</TableCell>
        </TableRow>
    );
}
