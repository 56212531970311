import Navigation from './Others/Navigation';
import DashBoard from './Dashboard/';
import Footer from './Others/Footer';

const Dashboard = () => {
    return (
        <>
            <div className="main">
                <Navigation />
                <DashBoard />
            </div>
            <Footer />
        </>
    );
};

export default Dashboard;
