import 'react-confirm-alert/src/react-confirm-alert.css';

import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';

import {
    addSpeedRate,
    postShiftTask,
    stopWork,
    switchWork,
} from '../../../actions/shiftActions';
import { getPrincipalsTasks } from '../../../actions/warehouseActions';
import CountTime from '../../HelperFunctions/CountTime';
import ReactSwipeButton from '../../Others/ReactSwipeButton';
import EndShiftModal from './EndShiftModal';

const InJob = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const shifts = useSelector((state) => state.shift);
    const tasks = useSelector((state) => state.tasks);
    const principalTasks = useSelector((state) => state.principalTasks);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [usingModal, setUsingModal] = useState(false);
    const [unit, setUnit] = useState('');

    // const [isVas, setIsVas] = useState(false);
    // const [vasWorkLog, setVasWorkLog] = useState([]);

    let latestTask = tasks.find((task) => task.end === null); // latest tasks
    const competedTasks = tasks.filter((task) => task.end !== null);

    const [latestShift, setLatestShift] = useState({});

    useEffect(() => {
        const x = shifts.find(
            (shift) => shift.check_out === null && shift.is_working && true
        );
        setLatestShift(x);
    }, [competedTasks, shifts, latestShift]);

    useEffect(() => {
        dispatch(
            getPrincipalsTasks(latestShift.warehouse, latestTask.principal_id)
        );
    }, [dispatch, latestShift, latestTask, shifts]);

    const start = Date.parse(latestTask.start);
    const breakTask = principalTasks?.find(
        (tasks) => tasks.description === 'BREAK-BUTTON'
    );

    useEffect(() => {
        let uni = '';

        const filtered = [];

        tasks.forEach((task) => {
            let ta = principalTasks?.find((t) => t.id === task.work_type);
            if (ta) {
                filtered.push(ta);
            }
        });

        const x = filtered?.find((tasks) => {
            const descriptions = tasks.description.split(',');
            uni = descriptions.find((e) => e.includes('UNIT'));

            return descriptions?.find(
                (description) => description === 'PERFORMANCE-END'
            );
        });
        if (uni) {
            setUnit(uni.split(':')[1].trim());
        }
        if (x) {
            setUsingModal(true);
        }
    }, [principalTasks, tasks, usingModal]);

    //vas related code
    // useEffect(() => {
    //     const currentPrincipalTask = principalTasks?.find(
    //         (t) => t.id === latestTask.work_type
    //     );
    //     console.log(currentPrincipalTask);
    //     if (currentPrincipalTask?.description.includes('VIEW-VAS')) {
    //         setIsVas(false);
    //     }
    // }, [principalTasks, latestTask]);

    // const handleVasWorkLog = async (newLog) => {
    //     try {
    //         const logWithSession = {
    //             ...newLog,
    //         };

    //         const existingDocId = await getVasWorkLogDocumentApi(
    //             latestShift.worker
    //         );

    //         if (existingDocId) {
    //             const existingLogIndex = vasWorkLog.findIndex(
    //                 (log) =>
    //                     log.productCode === newLog.productCode &&
    //                     log.startTime === newLog.startTime
    //             );

    //             let updatedLogs;
    //             if (existingLogIndex >= 0) {
    //                 updatedLogs = vasWorkLog.map((log, index) =>
    //                     index === existingLogIndex ? logWithSession : log
    //                 );
    //             } else {
    //                 updatedLogs = [...vasWorkLog, logWithSession];
    //             }

    //             const logData = {
    //                 vasWorkLog: updatedLogs,
    //             };
    //             await updateVasWorkLogApi(existingDocId, logData);
    //             setVasWorkLog(updatedLogs);
    //         } else {
    //             const logData = {
    //                 vasWorkLog: [logWithSession],
    //                 workerId: latestShift.worker,
    //                 shiftId: latestShift.id,
    //                 warehouseId: latestShift.warehouse,
    //             };
    //             await postVasWorkLogApi(logData);
    //             setVasWorkLog([logWithSession]);
    //         }
    //     } catch (error) {
    //         console.error('Error saving vas work log: ', error);
    //     }
    // };

    // useEffect(() => {
    //     const fetchExistingLogs = async () => {
    //         try {
    //             const existingDocId = await getVasWorkLogDocumentApi(
    //                 latestShift.worker
    //             );
    //             if (existingDocId) {
    //                 const docRef = doc(db, 'vas-work-logs', existingDocId);
    //                 const docSnap = await getDoc(docRef);
    //                 if (docSnap.exists()) {
    //                     const data = docSnap.data();
    //                     // Convert timestamps to dates in vasWorkLog array
    //                     const convertedLogs = data.vasWorkLog.map((log) => ({
    //                         ...log,
    //                         startTime:
    //                             log.startTime?.toDate?.() || log.startTime,
    //                         endTime: log.endTime?.toDate?.() || log.endTime,
    //                     }));
    //                     setVasWorkLog(convertedLogs);
    //                 }
    //             }
    //         } catch (error) {
    //             console.error('Error fetching vas work logs: ', error);
    //         }
    //     };

    //     if (latestShift?.worker) {
    //         fetchExistingLogs();
    //     }
    // }, [latestShift]);

    const switchWorking = async (event) => {
        setLoading(true);

        // Update vasWorkLog endTime if it exists
        // if (vasWorkLog.length > 0) {
        //     const now = new Date();
        //     // Find and update any logs without endTime
        //     const updatedLogs = vasWorkLog.map((log) => {
        //         if (!log.endTime) {
        //             return {
        //                 ...log,
        //                 endTime: now,
        //                 duration: now - new Date(log.startTime) / 1000,
        //             };
        //         }
        //         return log;
        //     });

        //     const existingDocId = await getVasWorkLogDocumentApi(
        //         latestShift.worker
        //     );
        //     if (existingDocId) {
        //         await updateVasWorkLogApi(existingDocId, {
        //             vasWorkLog: updatedLogs,
        //             sessionCompleted: true,
        //         });
        //     }
        // }

        await dispatch(
            switchWork(latestShift.id, latestTask.id, latestTask.work_type)
        );
        setLoading(false);
    };

    const stopWorking = async (event) => {
        setLoading(true);

        // if (vasWorkLog.length > 0) {
        //     const now = new Date();
        //     // Find and update any logs without endTime
        //     const updatedLogs = vasWorkLog.map((log) => {
        //         if (!log.endTime) {
        //             return {
        //                 ...log,
        //                 endTime: now,
        //                 duration: now - new Date(log.startTime) / 1000,
        //             };
        //         }
        //         return log;
        //     });

        //     const existingDocId = await getVasWorkLogDocumentApi(
        //         latestShift.worker
        //     );
        //     if (existingDocId) {
        //         await updateVasWorkLogApi(existingDocId, {
        //             vasWorkLog: updatedLogs,
        //             sessionCompleted: true,
        //         });
        //     }
        // }

        await dispatch(
            switchWork(latestShift.id, latestTask.id, latestTask.work_type)
        );
        await dispatch(stopWork(latestShift.id));
        navigate('/');
    };

    const startBreak = async () => {
        setLoading(true);
        await dispatch(
            switchWork(latestShift.id, latestTask.id, latestTask.work_type)
        );
        await dispatch(postShiftTask(latestShift.id, breakTask.id));
        setLoading(false);
    };

    const contineuWorking = async (event) => {
        setLoading(true);
        const competedTasksNoBreak = competedTasks.filter(
            (task) => task.work_type !== breakTask.id
        );
        competedTasksNoBreak.sort((a, b) => {
            return Date.parse(a.end) - Date.parse(b.end);
        });
        const previousTask =
            competedTasksNoBreak[competedTasksNoBreak.length - 1];
        await dispatch(
            switchWork(latestShift.id, latestTask.id, latestTask.work_type)
        );
        await dispatch(postShiftTask(latestShift.id, previousTask.work_type));
        setLoading(false);
    };

    const getTime = (timestamp) => {
        const dateObject = new Date(timestamp);
        const hours = ('0' + dateObject.getHours()).substr(-2);
        const minutes = ('0' + dateObject.getMinutes()).substr(-2);

        const result = `${hours}:${minutes}`;
        return result;
    };

    const getWorkedTime = () => {
        let sumTimeStamp = 0;
        competedTasks.forEach((task) => {
            const diff = Date.parse(task.end) - Date.parse(task.start);
            sumTimeStamp += diff;
        });
        return sumTimeStamp;
    };

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return;
        setOpen(false);
    };

    const handleSubmitModal = async (amouth) => {
        setOpen(false);
        setLoading(true);

        await dispatch(
            switchWork(latestShift.id, latestTask.id, latestTask.work_type)
        );
        await dispatch(stopWork(latestShift.id));
        await dispatch(addSpeedRate(latestShift.id, amouth, unit));
        navigate('/');
    };

    const handleOpenModal = () => {
        setOpen(true);
    };

    return (
        <div className="text-center mt-2">
            {/* <div>
                {isVas && (
                    <VasWorkCard
                        handleVasWorkLog={handleVasWorkLog}
                        vasWorkLog={vasWorkLog}
                        workerId={latestShift.worker}
                    />
                )}
            </div> */}
            <div className="pt-5">
                <h4 className="border rounded p-3 text-green">
                    <div className="row">
                        <div className="col-lg-3">
                            {latestTask.principal_name},{' '}
                            {t(latestTask.work_type_name)}
                        </div>
                        <div className="col-lg-6">
                            <div>
                                <CountTime startTime={start} />
                            </div>
                            {breakTask &&
                                breakTask.id !== latestTask.work_type && (
                                    <div className="my-3">
                                        <LoadingButton
                                            onClick={startBreak}
                                            loading={loading}
                                            loadingIndicator="Loading..."
                                            variant="contained"
                                        >
                                            {t('timer.startBreak')}
                                        </LoadingButton>
                                    </div>
                                )}
                            {breakTask &&
                                breakTask.id === latestTask.work_type && (
                                    <div className="my-3">
                                        <LoadingButton
                                            onClick={contineuWorking}
                                            loading={loading}
                                            loadingIndicator="Loading..."
                                            variant="contained"
                                        >
                                            {t('timer.continueWorking')}
                                        </LoadingButton>
                                    </div>
                                )}
                        </div>
                        <div className="col-lg-3 mt-2">
                            <ReactSwipeButton
                                text={t('timer.switchWorkType')}
                                color="#358dc9"
                                onSuccess={switchWorking}
                            />
                        </div>
                    </div>
                </h4>
                <h4 className="border rounded p-3 text-red">
                    <div className="row">
                        <div className="col-lg-3">
                            {t('timer.totalWorkingTime')}
                        </div>
                        <div className="col-lg-6">
                            <CountTime startTime={start - getWorkedTime()} />
                        </div>
                        <div className="col-lg-3 mt-2">
                            <ReactSwipeButton
                                text={t('timer.stopWorking')}
                                color="#de0E19"
                                onSuccess={
                                    usingModal ? handleOpenModal : stopWorking
                                }
                            />
                        </div>
                    </div>
                </h4>
                <hr />

                {competedTasks &&
                    competedTasks.reverse().map((task) => (
                        <h4 key={task.id} className="border rounded p-3">
                            <div className="row">
                                <div className="col-lg-3">
                                    {task.principal_name}, {task.work_type_name}
                                </div>
                                <div className="col-lg-6">{`${getTime(
                                    Date.parse(task.start)
                                )} - ${getTime(Date.parse(task.end))}`}</div>
                            </div>
                        </h4>
                    ))}
            </div>
            <EndShiftModal
                open={open}
                handleClose={handleClose}
                handleSubmitModal={handleSubmitModal}
                unit={unit}
            />
        </div>
    );
};
export default InJob;
