import { collection } from 'firebase/firestore';

import { db } from '../firebase';

export const shiftsCollectionRef = collection(db, 'shifts');

export const productsCollectionRef = collection(db, 'products');

export const inventoryCollectionRef = collection(db, 'inventory');

export const locationsCollectionRef = collection(db, 'locations');

export const speedRateCollectionRef = collection(db, 'employeeSpeedRate');

export const releasesCollectionRef = collection(db, 'releases');

export const absencesCollectionRef = collection(db, 'absences');

export const notificationsCollectionRef = collection(db, 'notification');

export const debugCollectionRef = collection(db, 'debug');

export const statisticsCollectionRef = collection(db, 'statistics');
export const sickleaveReasonsCollectionRef = collection(
    db,
    'statistics',
    'sickleaveReasons',
    'reasons'
);

export const teamsCollectionRef = (id) =>
    collection(db, 'teams', id.toString(), 'team');

export const offdayCollectionRef = collection(db, 'offday');

export const vasProductsCollectionRef = collection(db, 'vas-products');
export const vasWorkLogsCollectionRef = collection(db, 'vas-work-logs');
