const initialState = {
    vasProducts: [],
    loading: false,
    error: null,
};

export default function vasProductsReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_VAS_PRODUCTS_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'GET_VAS_PRODUCTS_SUCCESS':
            return {
                ...state,
                vasProducts: action.payload,
                loading: false,
            };
        case 'GET_VAS_PRODUCTS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'ADD_VAS_PRODUCT_SUCCESS':
            return {
                ...state,
                vasProducts: [...state.vasProducts, action.payload],
            };
        case 'DELETE_VAS_PRODUCT_SUCCESS':
            return {
                ...state,
                vasProducts: state.vasProducts.filter(
                    (product) => product.id !== action.payload
                ),
            };
        default:
            return state;
    }
}
