import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import { composeWithDevTools } from '@redux-devtools/extension';

import notificationReducer from './reducer/notificationReducer';
import PerformanceReducer from './reducer/PerformanceReducer';
import PrincipalReducer from './reducer/PrincipalReducer';
import principalTasksReducer from './reducer/principalTasksReducer';
import reportReducer from './reducer/reportReducer';
import scheduleReducer from './reducer/scheduleReducer';
import shiftReducer from './reducer/shiftReducer';
import userReducer from './reducer/userReducer';
import usersReducer from './reducer/usersReducer';
import vasProductsReducer from './reducer/vasProductsReducer';
import vasWorkLogReducer from './reducer/vasWorkLogReducer';
import warehouseReducer from './reducer/warehouseReducer';

const reducer = combineReducers({
    message: notificationReducer,
    user: userReducer,
    users: usersReducer,
    warehouses: warehouseReducer,
    principal: PrincipalReducer,
    tasks: scheduleReducer,
    report: reportReducer,
    shift: shiftReducer,
    principalTasks: principalTasksReducer,
    vasProducts: vasProductsReducer,
    performance: PerformanceReducer,
    vasWorkLog: vasWorkLogReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
