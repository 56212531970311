import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { FIREBASE_API } from './configs/config';

const firebaseConfig = {
    apiKey: FIREBASE_API.apiKey,
    authDomain: FIREBASE_API.authDomain,
    projectId: FIREBASE_API.projectId,
    storageBucket: FIREBASE_API.storageBucket,
    messagingSenderId: FIREBASE_API.messagingSenderId,
    appId: FIREBASE_API.appId,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const db = getFirestore(firebaseApp);

export const fetchToken = ({ setTokenFound, setToken }) => {
    return getToken(messaging, {
        vapidKey:
            'BCndqkOIWvPiTMi8YyPKrENNqaZo2-MTXpMyCiNrO5Zr9m6jdXXwjyu2ulyhEXM3p0Et6hXWOD24xK5IDpYxie0',
    })
        .then((currentToken) => {
            if (currentToken) {
                setTokenFound(true);
                setToken(currentToken);

                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log(
                    'No registration token available. Request permission to generate one.'
                );
                setTokenFound(false);
                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // catch error while creating client token
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
