import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    common: {
                        selectWarehouse: 'Select Warehouse',
                        selectPrincipal: 'Select Principal',
                        selectWorktype: 'Select Worktype',
                        selectEmployee: 'Select Employee',
                        update: 'Update',
                        live: 'Live',
                        done: 'Done',
                        close: 'Close',
                        or: 'Or',
                        send: 'Send',
                        cancel: 'Cancel',
                        error: 'Error',
                        next: 'Next',
                        finish: 'Finish',
                    },
                    login: {
                        header: 'Login',
                        email: 'Email',
                        password: 'Password',
                        login: 'Login',
                        noAccount: "Don't have an account?",
                        forgot: 'Forgot Password?',
                        registration: 'Sign Up',
                        logout: 'Logout',
                    },
                    navigation: {
                        dashboard: 'Dashboard',
                        homepage: 'Home',
                        tools: 'Tools',
                        setting: 'Setting',
                        instructions: 'Instructions',
                        stamp: 'Time card',
                        reports: 'Reports',
                        rules: 'In House Regulations',
                        reportAndStatistics: 'Report and Statistics',
                        workersPermission: 'Workers Permission',
                        liveStatistics: 'Live Statistics',
                        printReport: 'Print Report',
                        listOfEmployees: 'List of Employees',
                        marketShare: 'Market Share',
                        createShifts: 'Create Shifts',
                        'inventory-list': 'Inventory List',
                        'vas-products-list': 'VAS Products List',
                        sickleave: 'Sick Leave',
                        'sickleave-list': 'Sick Leave List',
                        safety: 'Safety',
                        rackSafety: 'Rack Safety',
                        machineSafety: 'Machine Safety',
                    },
                    timer: {
                        loginToWork: 'Start to Work',
                        selectWarehouse: 'Select Warehouse',
                        startWork: 'Start Work',
                        selectPrincipal: 'Select Principal',
                        selectWorktype: 'Select Worktype',
                        note: 'NOTE!',
                        noteText: 'You are currently logged in',
                        klo: 'from',
                        startWorking: 'Start Working',
                        stopWorking: 'Stop Working',
                        switchWorkType: 'Switch Work Type',
                        totalWorkingTime: 'Total Working Time',
                        warning: 'NOTE!',
                        warningText:
                            'You are choosing to stop the work. By choosing this, the time stamp will stop',
                        cancel: 'Cancel',
                        assignedShift: 'You have assigned shift at',
                        shiftStart: 'Start at',
                        startBreak: 'Start Break',
                        continueWorking: 'Continue Working',
                        performanceCalendar: 'How many calendars did you do?',
                        performanceCalendarBox: 'How many boxs did you do?',
                        performanceCalendarDescription: 'example: 100 pcs',
                    },
                    report: {
                        from: 'From',
                        to: 'To',
                        all: 'All',
                        search: 'Search',
                        date: 'Date',
                        notFound: 'Not Found',
                        startWorking: 'Start',
                        stopWorking: 'End',
                        warehouse: 'Warehouse',
                        principal: 'Principal',
                        task: 'Task',
                        workType: 'Work Type',
                        workTypeStart: 'Start',
                        workTypeEnd: 'End',
                        workingTime: 'Working Time (h:m)',
                        summary: 'Summary',
                        total: 'Total',
                        totalWorkingTime: 'Total Working Time',
                        workingHours: 'Working Hours',
                        employee: 'Employee',
                        checkInTime: 'Check In Time',
                        checkOutTime: 'Check Out Time',
                        report: 'Interval Report',
                        calendar: 'Calendars',
                        box: 'Boxes',
                        performance: 'Performance',
                        totalHours: 'Total Hours',
                        workTypeSummary: 'Work Type Summary',
                    },
                    setting: {
                        changePassword: 'Change Password',
                        newPassword: 'New Password',
                        confirmPassword: 'Confirm Password',
                        save: 'Save',
                        about: 'About',
                    },
                    instruction: {
                        instructions: 'Instructions',
                        startingWork: 'Starting Work',
                        switchWorkType: 'Switch Work Type',
                    },
                    sickleave: {
                        header: 'Sick leave',
                        title: 'Please take a photo or upload your sick leave certificate',
                        dropzoneDescription:
                            "Drag 'n' drop certificate here, or click to select files",
                        acceptedFiles: 'Accepted files: .jpg, .jpeg and .png',
                        errorAccept:
                            'Only .jpg, .jpeg and .png format allowed!',
                        uploadedSuccessfully:
                            'You sick leave certificate sent successfully',
                        uploadedError:
                            'Error while uploading sick leave certificate, please retake photo of certificate and try again.',
                        uploadStep1: 'Announce To Supervisor',
                        uploadStep2: 'Upload Certificate',
                        uploadStep3: 'Send Certificate',
                        uploadStep4: 'Waiting For Approval',
                        step1Description:
                            'Report sick leave to your supervisor',
                        step4Description1:
                            'We will notify you when your sick leave has been checked.',
                        'step4Description2-1':
                            'You can view your notifications in the bell icon',
                        'step4Description2-2': 'in top right corner.',
                    },
                    notification: {
                        notifications: 'Notifications',
                        'You-have': 'You have',
                        'unread-messages': 'unread messages',
                        unread: 'unread',
                        read: 'read',
                        'Mark-all-as-read': 'Mark all as read',
                        'You-sick-leave-is-processed':
                            'Your sick leave is processed:',
                        accepted: 'Accepted',
                        rejected: 'Rejected',
                        'Is-sick': 'Is sick',
                        'Is-offday': 'Is on holiday',
                    },
                    rules: {
                        rule1: "Arrive at the workplace on time and start work on schedule. When starting work, log into the app.dth.fi application with your DTH time card and select the assigned/approved work and finish at the end of the day. Do not stamp the client's time card in advance and then go for coffee!",
                        rule2: "After work is completed, change your work status to -> 'Temporary no work' and contact your nearest manager to arrange work for you.",
                        rule3: "When on a break, change your work status to -> 'Break' and change it back when starting work again in the app.dth.fi application.",
                        rule4: 'Correctly perform the tasks assigned and do not delegate or instruct others without approval.',
                        rule5: 'When working, wear the required clothing and protective equipment, such as safety shoes (mandatory), reflective clothing (mandatory), masks (as per location regulations), gloves, and maintain a 2-meter distance during Covid times.',
                        rule6: 'Keep your personal belongings in the designated/agreed place. If instructions are not followed, the company/client is not liable for lost items.',
                        rule7: 'Eating and drinking are only allowed in break areas. Taking drinks to production areas is strictly forbidden. Do not drink alcohol before work and during working hours.',
                        rule8: 'Do not smoke/use matches/lighters inside your workplace. Smoking is only allowed during breaks and in designated smoking areas. This applies to all client locations.',
                        rule9: 'Listening to music is prohibited at the workplace. To reduce the risk of accidents and to improve concentration in work, do not listen to music at the workplace.',
                        rule10: 'Do not use your phone for personal purposes while working. Inform your nearest manager if you have an urgent matter that requires your attention. Watching movies/videos during work hours is not allowed!!!',
                        rule11: "Follow general rules when working in client's warehouses/facilities.",
                        rule12: 'Do not use machinery or equipment without permission and guidance from a manager.',
                        rule13: "Work in designated/agreed work areas during work hours. Free movement to other areas is not allowed without the client's consent.",
                        rule14: "Everyone is responsible for protecting the company's and client's property. If you notice damaged/unusable items, inform your nearest manager.",
                        rule15: 'Always keep the workplace clean during and after work. Waste should be sorted at the agreed place.',
                        rule16: 'Inform your manager if any accidents or incidents occur in the work area, fill out accident/near-miss forms and send them directly to higher management. Report issues/discoveries in the app.dth.fi application (in progress) so your manager is aware and can prevent future hazards.',
                        rule17: "Do not bring friends to the work area without upper management's consent.",
                        rule18: 'Do not perform new tasks that you have not been trained/instructed for. All tasks should always be guided by trainer.',
                        rule19: "If you fall ill, call/schedule an appointment (pihlajalinna.fi -> occupational health) to see a doctor and immediately inform your nearest DTH manager. Report the number of sick days (photo from the doctor's certificate) in the app.dth.fi application.",
                        rule20: 'Clients have the right to ask you to assist in other tasks/new jobs and provide training before starting the work.',
                        rule21: "Communicate politely and appropriately with clients/client's workers.",
                        rule22: 'All statements related to DTH company in public media, press, social networks, etc., must be made by an authorized representative.',
                        rule23: "Any criminal activities or legal violations at the workplace are not acceptable and will be subject to the host country's (Finland) legal consequences.",
                        rule24: "Respect the property rights of others (including the company and clients), and do not take others' (including the company and clients) belongings out of the workplace without the manager's consent.",
                    },
                    'Pennala 1 - Unloading - Purku': 'Unloading',
                    'Pennala 1 - Receiving - Vastaanotto': 'Receiving',
                    'Pennala 1 - Putaway - Hyllytys': 'Putaway',
                    'Pennala 1 - Picking - Keräily': 'Picking',
                    'Pennala 1 - Training - Koulutus': 'Training',
                    'Pennala 1 - Replenishment - Täydennys': 'Replenishment',
                    'Pennala 1 - Meeting - Palaveri': 'Meeting',
                    'Pennala 1 - Production planning - Tuotannon suunnittelu':
                        'Production planning',
                    'Pennala 1 - Temporary no work - Ei töitä väliaikaisesti':
                        'Temporary no work',
                    'Pennala 1 - Labeling - Tarroittaminen': 'Labeling',
                    'Pennala 1 - Break - Tauko': 'Break',
                    'Pennala 1 - Cleaning - Siivous': 'Cleaning',
                    'Pennala 1 - Others - Varasto tukitoiminto projektit':
                        'Warehouse support function',
                    'Pennala 1 - Kuittaus & Pakkaus': 'Receipting & Packing',
                    'Pennala 1 - Waiting Replenishment - Täydennyksen odottaminen':
                        'Waiting Replenishment',
                    'Pennala 1 - Loading': 'Loading',
                    'Pennala 1 - Others': 'Others',

                    '------------------': '------------------',

                    'Pennala 2 - Unloading - Purku': 'Unloading',
                    'Pennala 2 - Receiving - Vastaanotto': 'Receiving',
                    'Pennala 2 - Putaway - Hyllytys': 'Putaway',
                    'Pennala 2 - Picking - Keräily': 'Picking',
                    'Pennala 2 - Dispatching - Lähetys': 'Dispatching',
                    'Pennala 2 - Quality control - Laatu': 'Quality control',
                    'Pennala 2 - Training - Koulutus': 'Training',
                    'Pennala 2 - Packing - Pakkaaminen': 'Packing',
                    'Pennala 2 - Replenishment - Täydennys': 'Replenishment',
                    'Pennala 2 - Meeting - Palaveri': 'Meeting',
                    'Pennala 2 - Production planning - Tuotannon suunnittelu':
                        'Production planning',
                    'Pennala 2 - Temporary no work - Ei töitä väliaikaisesti':
                        'Temporary no work',
                    'Pennala 2 - Labeling - Tarroittaminen': 'Labeling',
                    'Pennala 2 - Break - Tauko': 'Break',
                    'Pennala 2 - Change Battery - Akun vaihto':
                        'Change Battery',
                    'Pennala 2 - Cleaning - Siivous': 'Cleaning',
                    'Pennala 2 - Others - Varasto tukitoiminto projektit':
                        'Warehouse support function',
                    'Pennala 2 - RaKe kollikeräily': 'RaKe bulk collecting',
                    'Pennala 2 - RaKe Erikoistavarat': 'RaKe Erikoistavarat',
                    'Pennala 2 - Terminaali - Terminal': 'Terminal',
                },
            },
            fi: {
                translation: {
                    common: {
                        selectWarehouse: 'Valitse työpaikka, kustannuspaikka',
                        selectPrincipal: 'Valitse päämies',
                        selectWorktype: 'Valitse työlaji',
                        selectEmployee: 'Valitse työntekijä',
                        update: 'Päivitä',
                        live: 'Live',
                        done: 'Valmis',
                        close: 'Sulje',
                        or: 'Tai',
                        send: 'Lähetä',
                        cancel: 'Peruuta',
                        next: 'Seuraava',
                        finish: 'Valmis',
                    },
                    login: {
                        header: 'Kirjaudu sisään',
                        email: 'Sähköpostiosoite',
                        password: 'Salasana',
                        login: 'Kirjaudu',
                        noAccount: 'Eikö sinulla ole käyttäjätunnusta?',
                        forgot: 'Unohditko salasanasi?',
                        registration: 'Rekisteröidy',
                        logout: 'Kirjaudu ulos',
                    },
                    navigation: {
                        dashboard: 'Etusivu',
                        homepage: 'Etusivu',
                        tools: 'Työkalut',
                        setting: 'Asetukset',
                        instructions: 'Ohjeet',
                        stamp: 'Kellokortti',
                        reports: 'Raportit',
                        rules: 'Työsäännöt',
                        reportAndStatistics: 'Raportit ja tilastot',
                        workersPermission: 'Työntekijöiden oikeudet',
                        liveStatistics: 'Live Työ Status',
                        printReport: 'Tulosta raportti',
                        listOfEmployees: 'Työntekijälista',
                        marketShare: 'Jakaumat',
                        createShifts: 'Luo vuorot',
                        'inventory-list': 'Varastolista',
                        'vas-products-list': 'VAS tuotteet',
                        sickleave: 'Sairasloma',
                        'sickleave-list': 'Sairasloma Raportti',
                        safety: 'Turvallisuus',
                        rackSafety: 'Hyllyturvallisuus',
                        machineSafety: 'Laiteturvallisuus',
                    },
                    timer: {
                        loginToWork: 'Kirjaudu sisään',
                        selectWarehouse: 'Valitse työpaikka, kustannuspaikka',
                        startWork: 'Aloita työt',
                        selectPrincipal: 'Valitse päämies',
                        selectWorktype: 'Valitse työlaji',
                        note: 'HUOM!',
                        noteText: 'Olet tällä hetkellä kirjautunut',
                        klo: 'Klo',
                        startWorking: 'Aloita Työnteko',
                        stopWorking: 'Lopeta Työnteko',
                        switchWorkType: 'Vaihda työlaji',
                        totalWorkingTime: 'Työaika yhteensä',
                        warning: 'HUOM!',
                        warningText:
                            'Valitsit Lopeta työn. Kun valitset tämän, aikaleima pysähtyy',
                        cancel: 'Peruuta',
                        assignedShift: 'Sinulle on nimetty vuoro',
                        shiftStart: 'Vuoro alkaa',
                        startBreak: 'Aloita tauko',
                        continueWorking: 'Jatka työt',
                        performanceCalendar: 'Kuinka monta kalenteria teit?',
                        performanceCalendarBox:
                            'Kuinka monta kalenteri laatikkoa teit?',
                        performanceCalendarDescription: 'esimerkki: 100 kpl',
                    },
                    report: {
                        from: 'Alkaen',
                        to: 'Päättyen',
                        all: 'Kaikki',
                        search: 'Hae',
                        date: 'Päivämäärä',
                        notFound: 'Haku kriteereillä ei löytynyt',
                        startWorking: 'Aloitus',
                        stopWorking: 'Lopetus',
                        warehouse: 'Kustannuspaikka',
                        principal: 'Päämies',
                        task: 'Työ',
                        workType: 'Työlaji',
                        workTypeStart: 'Työn aloitus',
                        workTypeEnd: 'Työn lopetus',
                        workingTime: 'Työn kesto (h:m)',
                        summary: 'Yhteenveto',
                        total: 'yht.',
                        totalWorkingTime: 'Työpäivän pituus',
                        workingHours: 'Työtunnit',
                        employee: 'Työntekijä',
                        checkInTime: 'Kirjautumisaika',
                        checkOutTime: 'Lopetusaika',
                        report: 'Aikaväli Raportti',
                        calendar: 'Kalenteria',
                        box: 'Laatikkoa',
                        performance: 'Työsuoritus',
                        totalHours: 'Työtunnit',
                        workTypeSummary: 'Työlajien yhteenveto',
                    },
                    setting: {
                        changePassword: 'Vaihda salasana',
                        newPassword: 'Uusi salasana',
                        confirmPassword: 'Vahvista salasana',
                        save: 'Tallenna',
                        about: 'Tietoja sovelluksesta',
                    },
                    instruction: {
                        instructions: 'Ohjeet',
                        startingWork: 'Aloita työt',
                        switchWorkType: 'Vaihda työlaji',
                    },
                    sickleave: {
                        header: 'Sairasloma',
                        title: 'Ole Hyvä ota valokuva tai lataa sairauslomatodistus',
                        dropzoneDescription:
                            'Vedä ja pudota varmenne tähän tai valitse tiedostot napsauttamalla',
                        acceptedFiles:
                            'Hyväksytyt tiedostot: .jpg, .jpeg ja .png',
                        errorAccept:
                            'Vain .jpg, .jpeg ja .png muoto sallitaan!',
                        uploadedSuccessfully:
                            'Sairauslomatodistus lähetetty onnistuneesti',
                        uploadedError:
                            'Virhe ladattaessa sairauslomatodistusta. Ota valokuva todistuksesta uudelleen ja yritä uudelleen.',
                        uploadStep1: 'Ilmoita Esimiehelle',
                        uploadStep2: 'Lataa Todistus',
                        uploadStep3: 'Lähetä Todistus',
                        uploadStep4: 'Odota Vahvistusta',
                        step1Description: 'Ilmoita sairaslomasta esimiehelle',
                        step4Description1:
                            'Ilmoitamme sinulle kun sairauslomasi on tarkistettu.',
                        'step4Description2-1':
                            'Näet ilmoituksesi kellokuvakkeesta',
                        'step4Description2-2': 'oikeassa yläkulmassa.',
                    },
                    notification: {
                        notifications: 'Ilmoitukset',
                        'You-have': 'Sinulla on',
                        'unread-messages': 'lukematonta viestiä',
                        unread: 'lukematon',
                        read: 'luettu',
                        'Mark-all-as-read': 'Merkitse kaikki luetuksi',
                        'You-sick-leave-is-processed':
                            'Sairauslomasi on käsitelty:',
                        accepted: 'Hyväksytty',
                        rejected: 'Hylätty',
                        'Is-sick': 'On sairaana',
                        'Is-offday': 'on vapaalla',
                    },
                    rules: {
                        rule1: 'Saavu työpaikalle ajoissa ja aloita työ ajallaan. Työn alkaessa kirjaudu app.dth.fi-sovellukseen DTH-ajankortillasi ja valitse määrätty/hyväksytty työ ja lopeta työ päivän päätteeksi. Älä leimaa asiakkaan aikakorttia etukäteen ja mene sitten kahville!',
                        rule2: 'Työn päätyttyä vaihda työtila -> "Temporary no work" ja ota yhteyttä lähimpään esimieheesi työn järjestämiseksi sinulle.',
                        rule3: 'Tauolla ollessasi vaihda työtila -> "Tauko" ja vaihda se takaisin työn alkaessa app.dth.fi-sovelluksessa.',
                        rule4: 'Suorita annetut työtehtävät oikein äläkä delegoi tai ohjaa muita ilman hyväksyntää.',
                        rule5: 'Työskennellessäsi käytä vaadittuja vaatteita ja suojavarusteita, esim. turvakenkiä (pakolliset), heijastavia vaatteita (pakolliset), maskeja (paikan määräysten mukaan), käsineitä ja pidä 2 metrin etäisyys - Covid-aikana.',
                        rule6: 'Säilytä henkilökohtaiset tavarasi määrätyssä/sovitussa paikassa. Ohjeiden noudattamatta jättämisestä yritys/asiakas ei ole korvausvelvollinen kadonneista esineistä.',
                        rule7: 'Syöminen ja juominen sallittu vain taukotiloissa. Juomien vieminen tuotantoalueille on ankarasti kielletty. Ala juo alkoholia ennen työtä ja tyaikana.',
                        rule8: 'Älä polta tupakkaa/käytä tulitikkuja/sytyttimiä työpaikallasi sisätiloissa. Tupakointi sallittu vain tauoilla ja määrätyillä tupakointialueilla. Tämä koskee kaikkia asiakkaan toimipaikkoja.',
                        rule9: 'Musiikin kuunteleminen on kiellettyä työpaikalla. Onnettomuusriskin vähentämiseksi ja työskentelyn keskittymisen parantamiseksi älä kuuntele musiikkia työpaikalla',
                        rule10: 'Älä käytä puhelintasi henkilökohtaisiin tarkoituksiin työskennellessäsi. Ilmoita lähimmälle esimiehellesi, jos sinulla on kiireellinen asia, joka vaatii huomiotasi. Elokuvien/videoiden katsominen työaikana ei ole sallittua!!!',
                        rule11: 'Noudata yleisiä sääntöjä työskennellessäsi asiakkaan varastoissa/laitoksissa.',
                        rule12: 'Älä käytä koneita tai laitteita ilman luvan ja ohjeiden saamista esimieheltä.',
                        rule13: 'Työskentele määritetyillä/sovituilla työalueilla työaikana. Vapaa liikkuminen muihin alueisiin ei ole sallittua ilman asiakkaan suostumusta.',
                        rule14: 'Kaikkien on vastuussa yrityksen ja asiakkaan omaisuuden suojelemisesta. Jos huomaat vahingoittuneita/käyttökelvottomia tavaroita, ilmoita siitä lähimmälle esimiehellesi.',
                        rule15: 'Pidä aina työpaikka siistinä työskentelyn aikana ja sen jälkeen. Jätteet on lajiteltava sovitussa paikassa.',
                        rule16: 'Ilmoita esimiehellesi, jos työalueella tapahtuu onnettomuuksia tai välikohtauksia, täytä onnettomuus- /lähes onnettomuuslomakkeet ja lähetä ne suoraan ylemmälle johdolle. Raportoi app.dth.fi- sovelluksessa olevaan ”ongelmat/havainnot”-osioon (kesken), jotta esimiehesi tietää ja voi estää tulevia vaaroja.',
                        rule17: 'Älä tuo ystäviäsi työalueelle ilman ylemmän johdon suostumusta.',
                        rule18: 'Älä suorita uusia tehtäviä, joita et ole saanut opetusta/ohjeistusta varten. Kaikkiin tehtäviin annetaan aina ohjeistus ja koulutus kouluttajan toimesta.',
                        rule19: 'Sairastuessasi soita/varaa aika (pihlajalinna.fi -> työterveys) lääkärille ja ilmoita heti lähimmälle DTH:n esimiehellesi. Raportoi sairauspäivien määrä (lääkärintodistuksen kuva) app.dth.fi-sovellukseen.',
                        rule20: 'Asiakkaalla on oikeus pyytää sinua avustamaan muissa tehtävissä/uusissa töissä ja antamaan koulutusta ennen työhön ryhtymistä.',
                        rule21: 'Kommunikoi kohteliaasti ja asianmukaisesti asiakkaiden/asiakkaan työntekijöiden kanssa.',
                        rule22: 'Kaikki DTH-yritystä koskevat lausunnot julkisissa tiedotusvälineissä, lehdistössä, sosiaalisessa mediassa jne. on annettava valtuutetun edustajan toimesta.',
                        rule23: 'Kaikki työpaikalla tapahtuvat rikolliset toimet tai lainrikkomukset eivät ole hyväksyttäviä ja niistä seuraa isäntämaan (Suomi) lainsäädännön mukaiset seuraamukset.',
                        rule24: 'Kunnioita muiden (sekä yrityksen että asiakkaiden) omaisuusoikeuksia, äläkä ota muiden (sekä yrityksen että asiakkaiden) tavaroita työpaikalta pois ilman esimiehen suostumusta.',
                    },
                    'Pennala 1 - Unloading - Purku': 'Purku',
                    'Pennala 1 - Receiving - Vastaanotto': 'Vastaanotto',
                    'Pennala 1 - Putaway - Hyllytys': 'Hyllytys',
                    'Pennala 1 - Picking - Keräily': 'Keräily',
                    'Pennala 1 - Training - Koulutus': 'Koulutus',
                    'Pennala 1 - Replenishment - Täydennys': 'Täydennys',
                    'Pennala 1 - Meeting - Palaveri': 'Palaveri',
                    'Pennala 1 - Production planning - Tuotannon suunnittelu':
                        'Tuotannon suunnittelu',
                    'Pennala 1 - Temporary no work - Ei töitä väliaikaisesti':
                        'Ei töitä väliaikaisesti',
                    'Pennala 1 - Labeling - Tarroittaminen': 'Tarroittaminen',
                    'Pennala 1 - Break - Tauko': 'Tauko',
                    'Pennala 1 - Cleaning - Siivous': 'Siivous',
                    'Pennala 1 - Others - Varasto tukitoiminto projektit':
                        'Varasto tukitoiminto projektit',
                    'Pennala 1 - Kuittaus & Pakkaus': 'Kuittaus & Pakkaus',
                    'Pennala 1 - Waiting Replenishment - Täydennyksen odottaminen':
                        'Täydennyksen odottaminen',
                    'Pennala 1 - Loading': 'Loading',
                    'Pennala 1 - Others': 'Muu',

                    '------------------': '------------------',

                    'Pennala 2 - Unloading - Purku': 'Purku',
                    'Pennala 2 - Receiving - Vastaanotto': 'Vastaanotto',
                    'Pennala 2 - Putaway - Hyllytys': 'Hyllytys',
                    'Pennala 2 - Picking - Keräily': 'Keräily',
                    'Pennala 2 - Dispatching - Lähetys': 'Lähetys',
                    'Pennala 2 - Quality control - Laatu': 'Laatu',
                    'Pennala 2 - Training - Koulutus': 'Koulutus',
                    'Pennala 2 - Packing - Pakkaaminen': 'Pakkaaminen',
                    'Pennala 2 - Replenishment - Täydennys': 'Täydennys',
                    'Pennala 2 - Meeting - Palaveri': 'Palaveri',
                    'Pennala 2 - Production planning - Tuotannon suunnittelu':
                        'Tuotannon suunnittelu',
                    'Pennala 2 - Temporary no work - Ei töitä väliaikaisesti':
                        'Ei töitä väliaikaisesti',
                    'Pennala 2 - Labeling - Tarroittaminen': 'Tarroittaminen',
                    'Pennala 2 - Break - Tauko': 'Tauko',
                    'Pennala 2 - Change Battery - Akun vaihto': 'Akun vaihto',
                    'Pennala 2 - Cleaning - Siivous': 'Siivous',
                    'Pennala 2 - Others - Varasto tukitoiminto projektit':
                        'Varasto tukitoiminto projektit',
                    'Pennala 2 - RaKe kollikeräily': 'RaKe kollikeräily',
                    'Pennala 2 - RaKe Erikoistavarat': 'RaKe Erikoistavarat',
                    'Pennala 2 - Terminaali - Terminal': 'Terminaali',
                },
            },
            vi: {
                translation: {
                    common: {
                        selectWarehouse: 'Chọn kho',
                        selectPrincipal: 'Chọn khách hàng',
                        selectWorktype: 'Chọn loại công việc',
                        selectEmployee: 'Chọn nhân viên',
                        update: 'Cập nhật',
                        live: 'Live',
                        done: 'Hoàn thành',
                        close: 'Đóng',
                        or: 'Hoặc',
                        send: 'Gửi',
                        cancel: 'Hủy',
                        next: 'Tiếp theo',
                        finish: 'Hoàn thành',
                    },
                    login: {
                        header: 'Đăng nhập',
                        email: 'Địa chỉ email',
                        password: 'Mật khẩu',
                        login: 'Đăng nhập',
                        noAccount: 'Bạn chưa có tài khoản?',
                        forgot: 'Bạn quên mật khẩu?',
                        registration: 'Đăng ký',
                        logout: 'Đăng xuất',
                    },
                    navigation: {
                        dashboard: 'Trang chủ',
                        homepage: 'Trang chủ',
                        tools: 'Công cụ',
                        setting: 'Cài đặt',
                        instructions: 'Hướng dẫn',
                        stamp: 'Thẻ giờ',
                        reports: 'Báo cáo',
                        rules: 'Quy tắc làm việc',
                        reportAndStatistics: 'Báo cáo và thống kê',
                        workersPermission: 'Giới hạn của nhân viên',
                        liveStatistics: 'Thống kê trực tiếp',
                        printReport: 'In báo cáo',
                        listOfEmployees: 'Danh sách nhân viên',
                        marketShare: 'Thị phần',
                        createShifts: 'Tạo ca làm việc',
                        'inventory-list': 'Danh sách tồn kho',
                        'vas-products-list': 'Danh sách sản phẩm VAS',
                        sickleave: 'Nghỉ bệnh',
                        'sickleave-list': 'Danh sách nghỉ bệnh',
                        safety: 'Safety',
                        rackSafety: 'Rack Safety',
                        machineSafety: 'Machine Safety',
                    },
                    timer: {
                        loginToWork: 'Bắt đầu làm việc',
                        selectWarehouse: 'Chọn kho',
                        startWork: 'Bắt đầu làm việc',
                        selectPrincipal: 'Chọn khách hàng',
                        selectWorktype: 'Chọn loại công việc',
                        note: 'Chú ý!',
                        noteText: 'Bạn đã đăng nhập',
                        klo: 'từ',
                        startWorking: 'Bắt đầu làm việc',
                        stopWorking: 'Kết thúc làm việc',
                        switchWorkType: 'Chuyển loại công việc',
                        totalWorkingTime: 'Tổng thời gian làm việc',
                        warning: 'Cảnh báo!',
                        warningText:
                            'Bạn đang chọn dừng công việc. Bằng cách chọn này, dấu thời gian sẽ dừng lại',
                        cancel: 'Hủy',
                        assignedShift: 'bạn đã được phân công làm việc tại ',
                        shiftStart: 'Bắt đầu vào lúc',
                        startBreak: 'Bắt đầu giải lao',
                        continueWorking: 'Tiếp tục làm việc',
                        performanceCalendar: 'Bạn đã làm bao nhiêu cái lịch?',
                        performanceCalendarBox: 'Bạn đã làm bao nhiêu cái hộp?',
                        performanceCalendarDescription: 'thí dụ: 100 cái',
                    },
                    report: {
                        from: 'Từ',
                        to: 'Đến',
                        all: 'Tất cả',
                        search: 'Tìm',
                        date: 'Ngày',
                        notFound: 'Không tìm thấy',
                        startWorking: 'Bắt đầu',
                        stopWorking: 'Kết thúc',
                        warehouse: 'Kho',
                        principal: 'Khách hàng',
                        task: 'Công việc',
                        workType: 'Loại công việc',
                        workTypeStart: 'Bắt đầu',
                        workTypeEnd: 'Kết thúc',
                        workingTime: 'Thời gian làm việc (h:m)',
                        summary: 'Tổng hợp',
                        total: 'tổng',
                        totalWorkingTime: 'Tổng thời gian làm việc',
                        workingHours: 'Giờ làm việc',
                        employee: 'Nhân viên',
                        checkInTime: 'Thời gian vào',
                        checkOutTime: 'Thời gian ra',
                        report: 'Báo cáo',
                        calendar: 'Cái lịch',
                        box: 'cái hộp',
                        performance: 'Hiệu suất',
                        totalHours: 'Tổng giờ',
                        workTypeSummary: 'Tổng hợp loại công việc',
                    },
                    setting: {
                        changePassword: 'Đổi mật khẩu',
                        newPassword: 'Mật khẩu mới',
                        confirmPassword: 'Xác nhận mật khẩu',
                        save: 'Lưu',
                        about: 'Thông tin',
                    },
                    instruction: {
                        instructions: 'Hướng dẫn',
                        startingWork: 'Bắt đầu làm việc',
                        switchWorkType: 'Chuyển loại công việc',
                    },
                    sickleave: {
                        header: 'Nghỉ bệnh',
                        title: 'Vui lòng chụp ảnh hoặc đăng giấy chứng nhận của bác sĩ',
                        dropzoneDescription:
                            'Kéo và thả chứng chỉ tại đây hoặc bấm vào đây để chọn tệp',
                        acceptedFiles:
                            'Các tệp được chấp nhận: .jpg, .jpeg và .png',
                        errorAccept:
                            'Chỉ cho phép các định dạng .jpg, .jpeg và .png!',
                        uploadedSuccessfully:
                            'Giấy chứng nhận nghỉ bệnh của bạn đã được gửi thành công',
                        uploadedError:
                            'Đã xảy ra lỗi khi tải lên giấy chứng nhận nghỉ bệnh, vui lòng chụp lại ảnh của giấy chứng nhận và thử lại.',
                        uploadStep1: 'Thông báo cho người giám sát',
                        uploadStep2: 'Tải lên giấy nghỉ bệnh',
                        uploadStep3: 'Gửi giấy nghỉ bệnh',
                        uploadStep4: 'Chờ phê duyệt',
                        step1Description:
                            'Báo cáo nghỉ bệnh cho cấp trên của bạn',
                        step4Description1:
                            'Chúng tôi sẽ thông báo cho bạn khi thời gian nghỉ bệnh của bạn được kiểm tra.',
                        'step4Description2-1':
                            'Bạn sẽ thấy thông báo của mình trên biểu tượng chuông',
                        'step4Description2-2': 'ở góc trên bên phải.',
                    },
                    notification: {
                        notifications: 'Thông báo',
                        'You-have': 'Bạn có',
                        'unread-messages': 'tin nhắn chưa đọc',
                        unread: 'Chưa đọc',
                        read: 'Đã đọc',
                        'Mark-all-as-read': 'Đánh dấu tất cả là đã đọc',
                        'You-sick-leave-is-processed':
                            'Giấy chứng nhận nghỉ bệnh của bạn đã được xử lý:',
                        accepted: 'Đã chấp nhận',
                        rejected: 'Đã từ chối',
                        'Is-sick': 'Đã bệnh',
                        'Is-offday': 'Đã nghỉ phép',
                    },
                    rules: {
                        rule1: 'Đến nơi làm việc đúng giờ và bắt đầu làm việc đúng giờ. Khi bắt đầu công việc, hãy đăng nhập vào ứng dụng app.dth.fi bằng thẻ thời gian DTH của bạn và chọn công việc đã được chỉ định/chấp nhận và kết thúc công việc vào cuối ngày. Đừng đóng dấu thẻ giờ của khách hàng trước thời gian và sau đó đi uống cà phê!',
                        rule2: "Khi công việc kết thúc, hãy thay đổi trạng thái công việc thành -> 'Không có việc làm tạm thời' và liên hệ với người quản lý gần nhất của bạn để sắp xếp công việc.",
                        rule3: "Khi bạn nghỉ giải lao, hãy thay đổi trạng thái công việc thành -> 'Nghỉ giải lao' và thay đổi trở lại khi bạn bắt đầu làm việc trên ứng dụng app.dth.fi.",
                        rule4: 'Hoàn thành đúng các nhiệm vụ được giao và không tự ý giao việc hoặc hướng dẫn người khác mà không có sự chấp thuận.',
                        rule5: 'Khi làm việc, hãy mặc quần áo và trang bị bảo hộ cần thiết, ví dụ: giày bảo hộ (bắt buộc), quần áo phản quang (bắt buộc), khẩu trang (tùy theo quy định của địa điểm), găng tay và giữ khoảng cách 2 mét - trong thời gian Covid.',
                        rule6: 'Bảo quản đồ dùng cá nhân của bạn ở nơi được chỉ định/đã thỏa thuận. Nếu không tuân theo hướng dẫn, công ty/khách hàng sẽ không chịu trách nhiệm bồi thường cho đồ đạc bị mất.',
                        rule7: 'Ăn uống chỉ được phép trong khu vực nghỉ giải lao. Việc đưa đồ uống vào khu vực sản xuất bị nghiêm cấm. Không uống rượu trước và trong giờ làm việc.',
                        rule8: 'Không hút thuốc/sử dụng diêm/bật lửa trong khu vực làm việc của bạn. Chỉ được phép hút thuốc trong giờ nghỉ và tại những khu vực đã được chỉ định. Điều này áp dụng cho tất cả các địa điểm của khách hàng.',
                        rule9: 'Nghe nhạc tại nơi làm việc là không được phép. Để giảm thiểu nguy cơ tai nạn và cải thiện sự tập trung trong công việc, đừng nghe nhạc khi làm việc.',
                        rule10: 'Không sử dụng điện thoại cá nhân cho mục đích riêng trong giờ làm việc. Hãy thông báo cho người quản lý gần nhất của bạn nếu có vấn đề khẩn cấp cần giải quyết. Xem phim/video trong giờ làm việc là không được phép!!!',
                        rule11: 'Tuân theo các quy tắc chung khi làm việc tại các kho của khách hàng hoặc cơ sở của họ.',
                        rule12: 'Không sử dụng máy móc, thiết bị mà không có sự cho phép và hướng dẫn của người quản lý.',
                        rule13: 'Làm việc trong khu vực đã được chỉ định/đã thỏa thuận trong giờ làm việc. Không được tự do di chuyển đến các khu vực khác mà không có sự đồng ý của khách hàng.',
                        rule14: 'Mỗi người có trách nhiệm bảo vệ tài sản của công ty và hàng hóa của khách hàng. Nếu bạn phát hiện hàng hóa bị hỏng hoặc không sử dụng được, vui lòng thông báo cho người quản lý gần nhất của bạn.',
                        rule15: 'Luôn giữ cho nơi làm việc sạch sẽ trong và sau giờ làm việc. Rác thải phải được phân loại tại nơi đã thỏa thuận.',
                        rule16: 'Thông báo cho người quản lý của bạn nếu có tai nạn hoặc sự cố nào xảy ra tại khu vực làm việc, điền vào biểu mẫu tai nạn/gần tai nạn và gửi trực tiếp cho cấp trên. Báo cáo các vấn đề/phát hiện trong phần mềm app.dth.fi (đang trong quá trình hoàn thiện) để người quản lý biết và ngăn chặn rủi ro trong tương lai.',
                        rule17: 'Không được tự ý mang bạn bè đến khu vực làm việc nếu không có sự đồng ý của cấp trên.',
                        rule18: 'Không thực hiện công việc mới mà bạn chưa được đào tạo/hướng dẫn. Mọi công việc đều phải được hướng dẫn và đào tạo bởi một huấn luyện viên.',
                        rule19: 'Khi ốm, hãy gọi/đặt lịch hẹn (pihlajalinna.fi -> sức khỏe nghề nghiệp) để khám bác sĩ và thông báo ngay cho người quản lý DTH gần nhất của bạn. Báo cáo số ngày nghỉ ốm (hình ảnh từ giấy chứng nhận của bác sĩ) lên phần mềm app.dth.fi',
                        rule20: 'Khách hàng có quyền yêu cầu bạn tham gia hỗ trợ ở các khu vực khác/công việc mới và cung cấp đào tạo trước khi bạn bắt đầu công việc.',
                        rule21: 'Giao tiếp lịch sự và phù hợp với khách hàng và nhân viên của họ.',
                        rule22: 'Mọi phát ngôn liên quan đến công ty DTH trên các phương tiện thông tin đại chúng, báo chí, mạng xã hội, v.v. phải được thực hiện bởi người có thẩm quyền phát ngôn.',
                        rule23: 'Mọi hoạt động phạm tội hoặc vi phạm pháp luật tại nơi làm việc đều không được chấp nhận và sẽ phải chịu sự chế tài của pháp luật nước sở tại (Phần Lan).',
                        rule24: 'Tôn trọng quyền sở hữu của người khác (bao gồm cả công ty và khách hàng), không tự ý lấy đồ đạc của người khác (bao gồm cả của công ty và khách hàng) ra khỏi nơi làm việc nếu không có sự đồng ý của người quản lý.',
                    },
                    'Pennala 1 - Unloading - Purku': 'Unloading - Purku',
                    'Pennala 1 - Receiving - Vastaanotto':
                        'Receiving - Vastaanotto',
                    'Pennala 1 - Putaway - Hyllytys': 'Putaway - Hyllytys',
                    'Pennala 1 - Picking - Keräily': 'Picking - Keräily',
                    'Pennala 1 - Training - Koulutus': 'Training - Koulutus',
                    'Pennala 1 - Replenishment - Täydennys':
                        'Replenishment - Täydennys',
                    'Pennala 1 - Meeting - Palaveri': 'Meeting - Palaveri',
                    'Pennala 1 - Production planning - Tuotannon suunnittelu':
                        'Production planning - Tuotannon suunnittelu',
                    'Pennala 1 - Temporary no work - Ei töitä väliaikaisesti':
                        'Temporary no work - Ei töitä väliaikaisesti',
                    'Pennala 1 - Labeling - Tarroittaminen':
                        'Labeling - Tarroittaminen',
                    'Pennala 1 - Break - Tauko': 'Break - Tauko',
                    'Pennala 1 - Cleaning - Siivous': 'Cleaning - Siivous',
                    'Pennala 1 - Others - Varasto tukitoiminto projektit':
                        'Others - Varasto tukitoiminto projektit',
                    'Pennala 1 - Kuittaus & Pakkaus': 'Kuittaus & Pakkaus',
                    'Pennala 1 - Waiting Replenishment - Täydennyksen odottaminen':
                        'Waiting Replenishment - Täydennyksen odottaminen',
                    'Pennala 1 - Loading': 'Loading',
                    'Pennala 1 - Others': 'Others',

                    '------------------': '------------------',

                    'Pennala 2 - Unloading - Purku': 'Unloading - Purku',
                    'Pennala 2 - Receiving - Vastaanotto':
                        'Receiving - Vastaanotto',
                    'Pennala 2 - Putaway - Hyllytys': 'Putaway - Hyllytys',
                    'Pennala 2 - Picking - Keräily': 'Picking - Keräily',
                    'Pennala 2 - Dispatching - Lähetys':
                        'Dispatching - Lähetys',
                    'Pennala 2 - Quality control - Laatu':
                        'Quality control - Laatu',
                    'Pennala 2 - Training - Koulutus': 'Training - Koulutus',
                    'Pennala 2 - Packing - Pakkaaminen':
                        'Packing - Pakkaaminen',
                    'Pennala 2 - Replenishment - Täydennys':
                        'Replenishment - Täydennys',
                    'Pennala 2 - Meeting - Palaveri': 'Meeting - Palaveri',
                    'Pennala 2 - Production planning - Tuotannon suunnittelu':
                        'Production planning - Tuotannon suunnittelu',
                    'Pennala 2 - Temporary no work - Ei töitä väliaikaisesti':
                        'Temporary no work - Ei töitä väliaikaisesti',
                    'Pennala 2 - Labeling - Tarroittaminen':
                        'Labeling - Tarroittaminen',
                    'Pennala 2 - Break - Tauko': 'Break - Tauko',
                    'Pennala 2 - Change Battery - Akun vaihto':
                        'Change Battery - Akun vaihto',
                    'Pennala 2 - Cleaning - Siivous': 'Cleaning - Siivous',
                    'Pennala 2 - Others - Varasto tukitoiminto projektit':
                        'Others - Varasto tukitoiminto projektit',
                    'Pennala 2 - RaKe kollikeräily': 'RaKe kollikeräily',
                    'Pennala 2 - RaKe Erikoistavarat': 'RaKe Erikoistavarat',
                    'Pennala 2 - Terminaali - Terminal':
                        'Terminaali - Terminal',
                },
            },
        },
    });

export default i18n;
