import { getFilteredVasWorkLogsApi } from '../api/vasproduct';

export function getVasReport(searchData) {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'GETVASREPORT_REQUEST',
            });

            const filters = {
                startDay: new Date(searchData.startDate),
                endDay: new Date(searchData.endDate),
                workerIds:
                    searchData.worker !== 'All' ? [searchData.worker] : [],
                warehouseIds:
                    searchData.warehouse !== 'All'
                        ? [searchData.warehouse]
                        : [],
                principal:
                    searchData.principal !== 'All'
                        ? searchData.principal
                        : null,
                task: searchData.task !== 'All' ? searchData.task : null,
            };

            // Fetch data using the API
            const logs = await getFilteredVasWorkLogsApi(filters);

            // Dispatch action with the fetched data
            dispatch({
                type: 'GETVASREPORT_SUCCESS',
                payload: logs,
            });
        } catch (error) {
            console.error('ERROR', error);

            // Dispatch failure action
            dispatch({
                type: 'GETVASREPORT_FAILURE',
                payload: error.message || 'Failed to fetch VAS report',
            });
        }
    };
}
