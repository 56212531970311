import { Link } from 'react-router-dom';

export const IconLinkCard = ({ link, imageSrc, imageAlt, icon, title }) => {
    return (
        <div className="col-6 col-lg-4 mt-2">
            <Link to={link}>
                <div className="pt-2 text-center">
                    <div className="icon-color" style={{ fontSize: '120px' }}>
                        {imageSrc ? (
                            <img
                                src={imageSrc}
                                alt={imageAlt}
                                width="120px"
                                height="120px"
                            />
                        ) : (
                            icon
                        )}
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{title}</h5>
                    </div>
                </div>
            </Link>
        </div>
    );
};
