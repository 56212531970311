import { useTranslation } from 'react-i18next';

import listingworker from '../../../assets/images/listingworker.svg';
import piechart from '../../../assets/images/Pie_search_3.svg';
import searchlogo from '../../../assets/images/search.svg';
import { IconLinkCard } from '../IconLinkCard';

const ManagerReport = () => {
    const { t } = useTranslation();

    const reportItems = [
        {
            link: '/tools/reports/search',
            imageSrc: searchlogo,
            imageAlt: 'search logo',
            title: t('report.report'),
        },
        {
            link: '/tools/reports/statistics',
            imageSrc: piechart,
            imageAlt: 'pie-chart logo',
            title: t('navigation.marketShare'),
        },
        {
            link: '/tools/reports/employees',
            imageSrc: listingworker,
            imageAlt: 'employees logo',
            title: t('navigation.listOfEmployees'),
        },
        {
            link: '/tools/reports/bill',
            imageSrc: listingworker,
            imageAlt: 'billing report logo',
            title: 'Billing Report',
        },
        {
            link: '/tools/reports/vas-report',
            imageSrc: searchlogo,
            imageAlt: 'vas-report logo',
            title: 'VAS Report',
        },
    ];

    return (
        <div className="my-2 row">
            {reportItems.map((item, index) => (
                <IconLinkCard key={index} {...item} />
            ))}
        </div>
    );
};

export default ManagerReport;
