import React from 'react';

import { Container } from '@mui/material';

import CustomBreadcrumbs from '../../../../../components/custom-breadcrumbs';
import DayReport from './dayreport';
import RenderVasReport from './RenderVasReport';

const VasReport = () => {
    return (
        <Container>
            <CustomBreadcrumbs
                heading="VAS Report"
                links={[
                    { name: 'Dashboard', href: '/' },
                    { name: 'Tools', href: '/tools' },
                    { name: 'Reports', href: '/tools/reports' },
                    { name: 'VAS Report' },
                ]}
            />
            <DayReport />
            <RenderVasReport />
        </Container>
    );
};

export default VasReport;
