import { doc, getDoc } from 'firebase/firestore';

import {
    inventoryCollectionRef,
    locationsCollectionRef,
    productsCollectionRef,
    releasesCollectionRef,
    speedRateCollectionRef,
    teamsCollectionRef,
} from '../db/firestore';

const getCollectionRef = (path) => {
    switch (path) {
        case 'products':
            return productsCollectionRef;
        case 'inventory':
            return inventoryCollectionRef;
        case 'locations':
            return locationsCollectionRef;
        case 'employeeSpeedRate':
            return speedRateCollectionRef;
        case 'releases':
            return releasesCollectionRef;
        case 'teams':
            return teamsCollectionRef;
        default:
            return null;
    }
};

export const formatDoc = (doc) => {
    const data = doc.data();
    for (const property in data) {
        if (data[property]?.seconds) {
            data[property] = data[property].toDate();
        }
    }
    return {
        id: doc.id,
        ...data,
    };
};

export const formatDocs = (response) => {
    const res = response.docs.map((doc) => formatDoc(doc));
    return res;
};

export const formatArray = (array) => {
    let result = [];
    Promise.all(
        array.map(async (s) => {
            const parent = s.parent.path;
            const collectionRef = getCollectionRef(parent);
            const response = await getDoc(doc(collectionRef, s.id));
            result.push(formatDoc(response));
        })
    ).then(() => {
        return result;
    });
};
