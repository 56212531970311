import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

import { userIsIn } from './actions/userActions';
import Dasboard from './components/Dashboard';
import Login from './components/Login';

function App() {
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const token = cookies.get('rsid');
    useEffect(() => {
        if (token) {
            dispatch(userIsIn());
        }
    }, [dispatch, token]);

    const user = useSelector((state) => state.user);
    if (user && user.email) {
        return <Dasboard />;
    }
    return <Login />;
}

export default App;
